import {
  REPORTING_ALERTS_LOADED,
  REPORTING_CLEAR_SELECTED_EVENT,
  REPORTING_CLEAR_SELECTED_REPORT,
  REPORTING_CUSTOMER_SERVICE_HOURS_LOADED,
  REPORTING_EVENT_ADDED,
  REPORTING_EVENT_DELETED,
  REPORTING_EVENT_HOURS_ADDED,
  REPORTING_EVENT_MODIFIED,
  REPORTING_EVENT_SELECTED,
  REPORTING_EVENTS_LOADED,
  REPORTING_SCHEDULE_FOR_PARENTS_LOADED,
  REPORTING_SERVICE_REPORT_LOADED,
  REPORTING_SERVICE_REPORTS_LOADED,
  REPORTING_SET_REPORTS_GRID_MONTH,
  REPORTING_SET_REPORTS_GRID_YEAR,
  ReportingActions,
  ReportingState,
} from '../types';

const initialState: ReportingState = {
  mode: 'day',
  start: new Date(),
  end: new Date(),
  year: new Date().getFullYear(),
  month: new Date().getMonth() + 1,
};

export const reportingStateReducer = (
  state: ReportingState = initialState,
  action: ReportingActions
): ReportingState => {
  switch (action.type) {
    case REPORTING_EVENTS_LOADED: {
      return {...state, events: [...action.events], start: action.start, end: action.end, mode: action.mode};
    }

    case REPORTING_EVENT_ADDED: {
      let events = state.events ? [...state.events] : [];
      events.push({...action.event});
      return {...state, events: events};
    }

    case REPORTING_EVENT_MODIFIED: {
      const events =
        state.events?.map(event =>
          event.id === action.event.id
            ? {
                ...action.event,
              }
            : event
        ) ?? [];
      return {...state, events: events};
    }

    case REPORTING_EVENT_SELECTED: {
      return {...state, selectedEvent: {...action.event}};
    }

    case REPORTING_SERVICE_REPORT_LOADED: {
      return {...state, selectedReport: {...action.report}};
    }

    case REPORTING_SERVICE_REPORTS_LOADED: {
      return {...state, reports: [...action.reports]};
    }

    case REPORTING_ALERTS_LOADED: {
      return {...state, alerts: [...action.alerts]};
    }

    case REPORTING_CLEAR_SELECTED_EVENT: {
      return {...state, selectedEvent: undefined};
    }

    case REPORTING_CLEAR_SELECTED_REPORT: {
      return {...state, selectedReport: undefined};
    }

    case REPORTING_SET_REPORTS_GRID_YEAR: {
      return {...state, year: action.year};
    }

    case REPORTING_SET_REPORTS_GRID_MONTH: {
      return {...state, month: action.month};
    }

    case REPORTING_EVENT_DELETED: {
      const events = state.events?.filter(event => event.id !== action.id) ?? [];
      return {...state, events: events};
    }

    case REPORTING_EVENT_HOURS_ADDED: {
      return {
        ...state,
        reports: state.reports?.map(report => {
          if (report.eventId === action.report.eventId) {
            return {...action.report};
          }
          return report;
        }),
      };
    }

    case REPORTING_CUSTOMER_SERVICE_HOURS_LOADED: {
      return {...state, serviceHours: [...action.hours]};
    }

    case REPORTING_SCHEDULE_FOR_PARENTS_LOADED: {
      return {...state, scheduleForParents: {...action.schedules}};
    }

    default:
      return state;
  }
};
