import * as React from 'react';
import {useSelector} from 'react-redux';
import * as Sentry from '@sentry/react';
import {TranslatorProvider} from 'react-translate';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

import './App.css';

import {getTranslations} from './common/translations';
import {LoadingPage} from './pages/common/LoadingPage';
import {RequireAuth} from './components/common/RequireAuth';
import {ErrorBoundary} from './components/common/ErrorBoundary';
import {selectAuthState, selectCommonState} from './store/selectors';

const AlertsPage = React.lazy(() => import('./pages/AlertsPage'));
const Page403 = React.lazy(() => import('./pages/common/Page403'));
const Page404 = React.lazy(() => import('./pages/common/Page404'));
const ProfilePage = React.lazy(() => import('./pages/ProfilePage'));
const ReportsPage = React.lazy(() => import('./pages/ReportsPage'));
const AdminLayout = React.lazy(() => import('./layouts/AdminLayout'));
const LoginPage = React.lazy(() => import('./pages/common/LoginPage'));
const ErrorPage = React.lazy(() => import('./pages/common/ErrorPage'));
const CustomersPage = React.lazy(() => import('./pages/CustomersPage'));
const DashboardPage = React.lazy(() => import('./pages/DashboardPage'));
const SummariesPage = React.lazy(() => import('./pages/SummariesPage'));
const AddEditReportPage = React.lazy(() => import('./pages/AddEditReportPage'));
const ReportDetailsPage = React.lazy(() => import('./pages/ReportDetailsPage'));
const SummaryDetailsPage = React.lazy(() => import('./pages/SummaryDetailsPage'));
const CustomerDetailsPage = React.lazy(() => import('./pages/CustomerDetailsPage'));
const AddOrEditSummaryPage = React.lazy(() => import('./pages/AddOrEditSummaryPage'));
const CustomerServiceHoursPage = React.lazy(() => import('./pages/CustomerServiceHoursPage'));

const App: React.FunctionComponent = () => {
  const {user} = useSelector(selectAuthState);
  const {locale} = useSelector(selectCommonState);
  return (
    <TranslatorProvider translations={getTranslations(locale)}>
      <BrowserRouter>
        <ErrorBoundary user={user} locale={locale}>
          <React.Suspense fallback={<LoadingPage />}>
            <Routes>
              <Route path="/error" element={<ErrorPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/access-denied" element={<Page403 />} />
              <Route
                path="/"
                element={
                  <RequireAuth>
                    <AdminLayout />
                  </RequireAuth>
                }
              >
                <Route index element={<DashboardPage />} />
                <Route path="profile" element={<ProfilePage />} />
                <Route path="reports">
                  <Route path="event/:id/:workerId" element={<AddEditReportPage />} />
                  <Route path="hours" element={<CustomerServiceHoursPage />} />
                  <Route path="services" element={<ReportsPage />} />
                  <Route path="summaries/add" element={<AddOrEditSummaryPage />} />
                  <Route path="summaries/edit/:id" element={<AddOrEditSummaryPage />} />
                  <Route path="summaries/:id" element={<SummaryDetailsPage />} />
                  <Route path="summaries" element={<SummariesPage />} />
                  <Route path=":id" element={<ReportDetailsPage />} />
                </Route>
                <Route path="customers">
                  <Route index element={<CustomersPage />} />
                  <Route path=":id" element={<CustomerDetailsPage />} />
                </Route>
                <Route path="alerts" element={<AlertsPage />} />
                <Route path="*" element={<Page404 />} />
              </Route>
            </Routes>
          </React.Suspense>
        </ErrorBoundary>
      </BrowserRouter>
    </TranslatorProvider>
  );
};
export default Sentry.withProfiler(App);
