import {
  COMMON_CONFIG_LOADED,
  COMMON_LOCALE_CHANGED,
  COMMON_LOCATIONS_LOADED,
  COMMON_REQUEST_ENDED,
  COMMON_REQUEST_STARTING,
  COMMON_SIDEBAR_STATE_CHANGED,
  CommonActions,
  CommonState,
} from '../types';
import NetworkService from '../../common/NetworkService';

const initialState: CommonState = {
  config: {},
  locations: [],
  navOpen: true,
  isExecuting: false,
  locale: localStorage.getItem('reha_locale') ?? 'en-us',
};

export const commonStateReducer = (state: CommonState = initialState, action: CommonActions): CommonState => {
  switch (action.type) {
    case COMMON_REQUEST_STARTING:
    case COMMON_REQUEST_ENDED:
      return {
        ...state,
        isExecuting: action.type === COMMON_REQUEST_STARTING,
        executingModule: action.type === COMMON_REQUEST_STARTING ? action.module : undefined,
      };

    case COMMON_LOCALE_CHANGED:
      NetworkService.setLocale(action.locale);
      localStorage.setItem('reha_locale', action.locale);
      return {...state, locale: action.locale};

    case COMMON_CONFIG_LOADED:
      return {...state, config: {...action.config}};

    case COMMON_LOCATIONS_LOADED:
      return {...state, locations: [...action.locations]};

    case COMMON_SIDEBAR_STATE_CHANGED:
      return {...state, navOpen: action.isOpen};

    default:
      return state;
  }
};
