import {Translations} from 'react-translate';

export const english: Translations = {
  locale: 'en',
  AdminLayout: {
    Logout: 'Logout',
    Profile: 'Profile',
    Admin: 'Admin',
    Error: 'Error',
    Dashboard: 'Dashboard',
    'Service Reports': 'Service Reports',
    'en-us': 'English',
    'et-ee': 'Eesti keel',
    Customers: 'Customers',
    Alerts: 'Alerts',
    Reports: 'Reports',
    'Service Hours': 'Service Hours',
    'Service Summaries': 'Service Summaries',
    '2FA now available! Make your account more secure and activate 2FA!':
      '2FA now available! Make your account more secure and activate 2FA!',
    'Go to activate': 'Go to activate',
    'Loading...': 'Loading...',
  },
  Sidebar: {
    'Main Menu': 'Main Menu',
  },
  ProfilePage: {
    'My Profile': 'My Profile',
    Administrator: 'Administrator',
    Worker: 'Worker',
    Details: 'Details',
    'Change Password': 'Change Password',
    Username: 'Username',
    'E-mail Address': 'E-mail Address',
    'Color in Schedule': 'Color in Schedule',
    'Preferred Language': 'Preferred Language',
    'en-us': 'English',
    'et-ee': 'Eesti keel',
    Security: 'Security',
    '2FA Enabled?': '2FA Enabled?',
    Yes: 'Yes',
    No: 'No',
    'Disable 2FA': 'Disable 2FA',
    'Two factor authentication has been successfully disabled!':
      'Two factor authentication has been successfully disabled!',
    'Are you sure you want to disable 2FA?': 'Are you sure you want to disable 2FA?',
    'Current Password': 'Current Password',
    Disable: 'Disable',
    Cancel: 'Cancel',
    Edit: 'Edit',
    'Edit Profile': 'Edit Profile',
    'First Name': 'First Name',
    'Last Name': 'Last Name',
    English: 'English',
    Estonian: 'Estonian',
    'Valid E-mail Address required': 'Valid E-mail Address required',
    'E-mail Address is required': 'E-mail Address is required',
    'First Name is required': 'First Name is required',
    'Last Name is required': 'Last Name is required',
    'Max 150 characters': 'Max 150 characters',
    'Profile Updated': 'Profile Updated',
    'Your changes have been successfully saved!': 'Your changes have been successfully saved!',
    'No color': '- No color -',
    'No locale': '- No prefference -',
    '2FA Code from the Authenticator App is required': '2FA Code from the Authenticator App is required',
    '2FA Code is 6 characthers': '2FA Code is 6 characthers',
    'Current Password is required': 'Current Password is required',
    '2FA Code': '2FA Code',
    '2FA Code from Authenticator App': '2FA Code from Authenticator App',
  },
  PasswordForm: {
    'Old Password is required': 'Old Password is required',
    'New Password is required': 'New Password is required',
    'New password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 symbol out of {{symbols}}':
      'New password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 symbol out of {{symbols}}',
    'Repeat the new password': 'Repeat the new password',
    'Passwords must match': 'Passwords must match',
    'Old Password': 'Old Password',
    'New Password': 'New Password',
    'Repeat New Password': 'Repeat New Password',
    'Change Password': 'Change Password',
    'Your password has been successfully changed': 'Your password has been successfully changed',
    'New password should be at least 8 characters': 'New password should be at least 8 characters',
    '2FA Code from Authenticator App is required': '2FA Code from Authenticator App is required',
    '2FA Code is 6 characthers': '2FA Code is 6 characthers',
    '2FA Code': '2FA Code',
  },
  DashboardPage: {
    Dashboard: 'Dashboard',
    'Schedule For Parents': 'Schedule For Parents',
    'Print Schedule': 'Print Schedule',
    Reload: 'Reload',
  },
  WorkerCalendar: {
    day: 'Day',
    week: 'Week',
    month: 'Month',
    today: 'Today',
    prev: 'Previous',
    next: 'Next',
  },
  AdminCalendar: {
    day: 'Day',
    week: 'Week',
    month: 'Month',
    today: 'Today',
    prev: 'Previous',
    next: 'Next',
    Delete: 'Delete',
    'Are you sure you want to delete the event xxx?': 'Are you sure you want to delete the event {{name}}?',
    'Are you adding a GROUP appointment?': 'Are you adding a GROUP appointment?',
  },
  EventManagementService: {
    'Wrong timeslot': 'Wrong timeslot',
    'We can plan only ahead of time.': 'We can plan only ahead of time.',
    'No services': 'No services',
    'There are no services scheduled for dayOfWeek!': 'There are no services scheduled for {{dayOfWeek}}!',
    'No providers': 'No providers',
    'There are no providers for any of those locations!': 'There are no providers for any of those locations!',
  },
  EventFormUI: {
    'Start Date': 'Start Date',
    'End Date': 'End Date',
    Customer: 'Customer',
    Specialist: 'Specialist',
    Location: 'Location',
    Service: 'Service',
    'Reha Plan Missing': 'Reha Plan Missing',
    'There is no active reha plan for date': 'There is no active reha plan for {{date}}!',
  },
  GroupEventFormUI: {
    'Start Date': 'Start Date',
    'End Date': 'End Date',
    Customer: 'Customer',
    Specialist: 'Specialist',
    Location: 'Location',
    Service: 'Service',
    'Add Row': 'Add Row',
  },
  ConfirmationModal: {
    'Confirm Action': 'Confirm Action',
    Yes: 'Yes',
    No: 'No',
  },
  EventModal: {
    'Event start date is required': 'Event start date is required',
    'Minimum start date is current time': 'Minimum start date is current time',
    'Event end date is required': 'Event end date is required',
    'Event end date must be greater than or equal to start date':
      'Event end date must be greater than or equal to start date',
    'At least one customer is required': 'At least one customer is required',
    'At least one worker is required': 'At least one worker is required',
    'At least one location is required': 'At least one location is required',
    'Add New Service Event': 'Add New Service Event',
    'At least on service is required': 'At least on service is required',
  },
  GroupEventModal: {
    'Event start date is required': 'Event start date is required',
    'Minimum start date is current time': 'Minimum start date is current time',
    'Event end date is required': 'Event end date is required',
    'Event end date must be greater than or equal to start date':
      'Event end date must be greater than or equal to start date',
    'At least one location is required': 'At least one location is required',
    'At least one customer is required': 'At least one customer is required',
    'At least one worker is required': 'At least one worker is required',
    'At least on service is required': 'At least on service is required',
    'At least one group is required': 'At least one group is required',
    'Add New Group Service Event': 'Add New Group Service Event',
  },
  FbStyleLoader: {
    'Executing...': 'Executing...',
  },
  Loader: {
    'Loading...': 'Loading...',
  },
  ModalWithForm: {
    Save: 'Save',
    Close: 'Close',
  },
  DatePickerField: {
    Time: 'Time',
  },
  TypeaheadField: {
    Duplication: 'Duplication',
    'Type in and select a value: ': 'Type in and select a value: ',
    'Duplicate item not added: {{duplicate}}': 'Duplicate item not added: {{duplicate}}',
  },
  ErrorPage: {
    'Go Back': 'Go Back',
    'Server Error': 'Server Error',
    "It's not you, it's me.": "It's not you, it's me.",
  },
  Page404: {
    Home: 'Home',
    'Page not found.': 'Page not found.',
  },
  LoginPage: {
    'Username is required': 'Username is required',
    'Password is required': 'Password is required',
    'Sign In': 'Sign In',
    Username: 'Username',
    Password: 'Password',
    '2FA Code from Authenticator App is required': '2FA Code from Authenticator App is required',
    '2FA Code is 6 characthers': '2FA Code is 6 characthers',
    '2FA Code': '2FA Code',
    '2FA Code from Authenticator App': '2FA Code from Authenticator App',
  },
  AddEditReportPage: {
    'Service Report': 'Service Report',
    Amount: 'Amount',
    Objectives: 'Objectives',
    Description: 'Description',
    Remarks: 'Remarks',
    'Save Report': 'Save Report',
    'For Worker': 'For Worker',
    'Objectives of the session are required': 'Objectives of the session are required',
    'Try to squeeze into xxx chars.': 'Try to squeeze into {{count}} chars.',
    'Session description is required': 'Session description is required',
    Worker: 'Worker(s)',
  },
  ClearEventsModal: {
    'Clear Old events': 'Clear Old Events',
    'Date is required': 'Date is required',
    'Only older than dday events': 'Only older than {{date}} events',
  },
  ClearEventsFormUI: {
    Date: 'Date',
    'Events removed will be the ones less than given date': 'Events removed will be the ones older than given date',
  },
  GenerateEventsModal: {
    'Generate Events': 'Generate Events',
    'Positive numbers only': 'Positive numbers only',
    'Month is required': 'Month is required',
    'Minimum month number is 1': 'Minimum month number is 1',
    'Maximum month number is 12': 'Maximum month number is 12',
    'Year is required': 'Year is required',
    'Current year is minimum': 'Current year is minimum',
    'Future dates please': 'Future dates please',
  },
  GenerateEventsFormUI: {
    Month: 'Month',
    Year: 'Year',
  },
  ReportsPage: {
    'Report is missing!': 'Report is missing!',
    Date: 'Date',
    Customer: 'Customer',
    Service: 'Service',
    Location: 'Location',
    Worker: 'Worker',
    Amount: 'Amount',
    Objectives: 'Objectives',
    Description: 'Description',
    Remarks: 'Remarks',
    'Service Reports': 'Service Reports',
    Year: 'Year',
    Month: 'Month',
    'Export Reports': 'Export Reports',
    Reload: 'Reload',
    'Export My Reports': 'Export My Reports',
    'Create Report Without Data': 'Create Report Without Data',
    'Hours of the event #id have been added to report': 'Hours of the event #{{id}} have been added to report',
    'Are you sure you would like to add just hours for this report?':
      'Are you sure you would like to add just hours for this report?',
    Edit: 'Edit',
    'Add Empty Report': 'Add Empty Report',
  },
  ExportReportsModal: {
    'Select Reports to Export': 'Select Reports to Export',
    'At least one report should be selected': 'At least one report should be selected',
    'After exporting the reports, you cannot edit those reports anymore!':
      'After exporting the reports, you cannot edit those reports anymore!',
    'Select All': 'Select All',
    'Unselect All': 'Unselect All',
    'Select individual customers': 'Select individual customers...',
    'Select Events to Add an Empty Report': 'Select Events to Add an Empty Report',
  },
  ReportDetailsPage: {
    'Report Error': 'Report Error',
    'Report with ID xxx not found': 'Report with ID #{{id}} not found',
    'Service Report Details': 'Service Report Details',
    Objectives: 'Objectives',
    Description: 'Description',
    Remarks: 'Remarks',
    Reload: 'Reload',
    Worker: 'Worker',
  },
  CustomersPage: {
    'Full Name': 'Full Name',
    'Service Location': 'Service Location',
    'Social Security No.': 'Social Security No.',
    'Transfer Letter No.': 'Transfer Letter No.',
    'Transfer Letter Valid Until': 'Transfer Letter Valid Until',
    'Disability Valid Until': 'Disability Valid Until',
    'Reha Plan No.': 'Reha Plan No.',
    'Reha Plan Valid Until': 'Reha Plan Valid Until',
    Customers: 'Customers',
    Reload: 'Reload',
    Age: 'Age',
  },
  CustomerDetailsPage: {
    'Full Name': 'Full Name',
    'Service Location': 'Service Location',
    'Social Security No.': 'Social Security No.',
    'Transfer Letter No.': 'Transfer Letter No.',
    'Transfer Letter Valid Until': 'Transfer Letter Valid Until',
    'Disability Valid Until': 'Disability Valid Until',
    'Reha Plan No.': 'Reha Plan No.',
    'Reha Plan Valid From': 'Reha Plan Valid From',
    'Reha Plan Valid Until': 'Reha Plan Valid Until',
    'Customer Details': 'Customer Details',
    Reload: 'Reload',
    Contacts: 'Legal Representative Contacts',
    Services: 'Services',
    Availabilities: 'Availabilities',
    Name: 'Name',
    Email: 'E-mail',
    Phone: 'Phone',
    Code: 'Code',
    'Amount (per year)': 'Amount (per year)',
    Objectives: 'Objectives',
    Recommendations: 'Recommendations',
    'Day of Week': 'Day of Week',
    Since: 'Since',
    Until: 'Until',
    E: 'Mondays',
    T: 'Tuesdays',
    K: 'Wednesdays',
    N: 'Thursdays',
    R: 'Fridays',
    L: 'Saturdays',
    P: 'Sundays',
    'Download Reha Plan Document': 'Download Reha Plan Document',
    Threshold: 'Threshold',
    Active: 'Active',
    Inactive: 'Inactive',
    Terminated: 'Terminated',
    Diagnosis: 'Diagnosis',
    Status: 'Status',
    Files: 'Files',
    Document: 'Document',
    Added: 'Added',
    'Download Document': 'Download Document',
    Age: 'Age',
    'Preview Reha Plan Document': 'Preview Reha Plan Document',
    'Preview Document': 'Preview Document',
    'Transfer Letter Valid From': 'Transfer Letter Valid From',
    'Transfer Letter Issuer': 'Transfer Letter Issuer',
    'Disability Valid From': 'Disability Valid From',
    'Disability Level': 'Disability Level',
    'Disability Type': 'Disability Type',
    medium: 'Medium',
    severe: 'Severe',
    deep: 'Deep',
    null: '',
    Type: 'Type',
    Remarks: 'Remarks',
    parent: 'Parent',
    kov: 'KOV',
    other: 'Other',
    'Sum Per Period': 'Sum Per Transfer Letter Period',
    Role: 'Role',
  },
  AlertsPage: {
    Customer: 'Customer',
    Alert: 'Alert',
    Value: 'Expires At',
    Alerts: 'Alerts',
    Reload: 'Reload',
  },
  MissedEventsModal: {
    'Events Not Generated': 'Events Not Generated',
    Close: 'Close',
    Customer: 'Customer',
    Service: 'Service',
    Worker: 'Worker',
    Explanation: 'Explanation',
  },
  CustomerServiceHoursPage: {
    'Customer Service Hours Report': 'Customer Service Hours Report',
    Reload: 'Reload',
    Customer: 'Customer',
    Service: 'Service',
    Jan: 'Jan',
    Feb: 'Feb',
    Mar: 'Mar',
    Apr: 'Apr',
    May: 'May',
    Jun: 'Jun',
    Jul: 'Jul',
    Aug: 'Aug',
    Sep: 'Sep',
    Oct: 'Oct',
    Nov: 'Nov',
    Dec: 'Dec',
    'Total Hours': 'Total Hours',
    'Hours Used': 'Hours Used',
    'Hours Spare': 'Hours Spare',
    'Total Money': 'Total Money',
    'Money Spent': 'Money Spent',
    'Money Spare': 'Money Spare',
  },
  FilePreviewModal: {
    'File Preview': 'File Preview',
    Download: 'Download',
  },
  AgGrid: {
    // Set Filter
    selectAll: '(Select All)',
    selectAllSearchResults: '(Select All Search Results)',
    searchOoo: 'Search...',
    blanks: '(Blanks)',
    noMatches: 'No matches',

    // Number Filter & Text Filter
    filterOoo: 'Filter...',
    equals: 'Equals',
    notEqual: 'Not equal',
    empty: 'Choose One',

    // Number Filter
    lessThan: 'Less than',
    greaterThan: 'Greater than',
    lessThanOrEqual: 'Less than or equal',
    greaterThanOrEqual: 'Greater than or equal',
    inRange: 'In range',
    inRangeStart: 'from',
    inRangeEnd: 'to',

    // Text Filter
    contains: 'Contains',
    notContains: 'Not contains',
    startsWith: 'Starts with',
    endsWith: 'Ends with',

    // Date Filter
    dateFormatOoo: 'yyyy-mm-dd',

    // Filter Conditions
    andCondition: 'AND',
    orCondition: 'OR',

    // Filter Buttons
    applyFilter: 'Apply',
    resetFilter: 'Reset',
    clearFilter: 'Clear',
    cancelFilter: 'Cancel',

    // Filter Titles
    textFilter: 'Text Filter',
    numberFilter: 'Number Filter',
    dateFilter: 'Date Filter',
    setFilter: 'Set Filter',

    // Side Bar
    columns: 'Columns',
    filters: 'Filters',

    // columns tool panel
    pivotMode: 'Pivot Mode',
    groups: 'Row Groups',
    rowGroupColumnsEmptyMessage: 'Drag here to set row groups',
    values: 'Values',
    valueColumnsEmptyMessage: 'Drag here to aggregate',
    pivots: 'Column Labels',
    pivotColumnsEmptyMessage: 'Drag here to set column labels',

    // Header of the Default Group Column
    group: 'Group',

    // Other
    loadingOoo: 'Loading...',
    noRowsToShow: 'No Rows To Show',
    enabled: 'Enabled',

    // Menu
    pinColumn: 'Pin Column',
    pinLeft: 'Pin Left',
    pinRight: 'Pin Right',
    noPin: 'No Pin',
    valueAggregation: 'Value Aggregation',
    autosizeThiscolumn: 'Autosize This Column',
    autosizeAllColumns: 'Autosize All Columns',
    groupBy: 'Group by',
    ungroupBy: 'Un-Group by',
    resetColumns: 'Reset Columns',
    expandAll: 'Expand All',
    collapseAll: 'Close All',
    copy: 'Copy',
    ctrlC: 'Ctrl+C',
    copyWithHeaders: 'Copy With Headers',
    paste: 'Paste',
    ctrlV: 'Ctrl+V',
    export: 'Export',
    csvExport: 'CSV Export',
    excelExport: 'Excel Export',

    // Enterprise Menu Aggregation and Status Bar
    sum: 'Sum',
    min: 'Min',
    max: 'Max',
    none: 'None',
    count: 'Count',
    avg: 'Average',
    filteredRows: 'Filtered',
    selectedRows: 'Selected',
    totalRows: 'Total Rows',
    totalAndFilteredRows: 'Rows',
    more: 'More',
    to: 'to',
    of: 'of',
    page: 'Page',
    nextPage: 'Next Page',
    lastPage: 'Last Page',
    firstPage: 'First Page',
    previousPage: 'Previous Page',

    // Pivoting
    pivotColumnGroupTotals: 'Total',

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
    pivotChart: 'Pivot Chart',
    chartRange: 'Chart Range',

    columnChart: 'Column',
    groupedColumn: 'Grouped',
    stackedColumn: 'Stacked',
    normalizedColumn: '100% Stacked',

    barChart: 'Bar',
    groupedBar: 'Grouped',
    stackedBar: 'Stacked',
    normalizedBar: '100% Stacked',

    pieChart: 'Pie',
    pie: 'Pie',
    doughnut: 'Doughnut',

    line: 'Line',

    xyChart: 'X Y (Scatter)',
    scatter: 'Scatter',
    bubble: 'Bubble',

    areaChart: 'Area',
    area: 'Area',
    stackedArea: 'Stacked',
    normalizedArea: '100% Stacked',

    histogramChart: 'Histogram',

    // Charts
    pivotChartTitle: 'Pivot Chart',
    rangeChartTitle: 'Range Chart',
    settings: 'Settings',
    data: 'Data',
    format: 'Format',
    categories: 'Categories',
    defaultCategory: '(None)',
    series: 'Series',
    xyValues: 'X Y Values',
    paired: 'Paired Mode',
    axis: 'Axis',
    navigator: 'Navigator',
    color: 'Color',
    thickness: 'Thickness',
    xType: 'X Type',
    automatic: 'Automatic',
    category: 'Category',
    number: 'Number',
    time: 'Time',
    xRotation: 'X Rotation',
    yRotation: 'Y Rotation',
    ticks: 'Ticks',
    width: 'Width',
    height: 'Height',
    length: 'Length',
    padding: 'Padding',
    spacing: 'Spacing',
    chart: 'Chart',
    title: 'Title',
    titlePlaceholder: 'Chart title - double click to edit',
    background: 'Background',
    font: 'Font',
    top: 'Top',
    right: 'Right',
    bottom: 'Bottom',
    left: 'Left',
    labels: 'Labels',
    size: 'Size',
    minSize: 'Minimum Size',
    maxSize: 'Maximum Size',
    legend: 'Legend',
    position: 'Position',
    markerSize: 'Marker Size',
    markerStroke: 'Marker Stroke',
    markerPadding: 'Marker Padding',
    itemSpacing: 'Item Spacing',
    itemPaddingX: 'Item Padding X',
    itemPaddingY: 'Item Padding Y',
    layoutHorizontalSpacing: 'Horizontal Spacing',
    layoutVerticalSpacing: 'Vertical Spacing',
    strokeWidth: 'Stroke Width',
    offset: 'Offset',
    offsets: 'Offsets',
    tooltips: 'Tooltips',
    callout: 'Callout',
    markers: 'Markers',
    shadow: 'Shadow',
    blur: 'Blur',
    xOffset: 'X Offset',
    yOffset: 'Y Offset',
    lineWidth: 'Line Width',
    normal: 'Normal',
    bold: 'Bold',
    italic: 'Italic',
    boldItalic: 'Bold Italic',
    predefined: 'Predefined',
    fillOpacity: 'Fill Opacity',
    strokeOpacity: 'Line Opacity',
    histogramBinCount: 'Bin count',
    columnGroup: 'Column',
    barGroup: 'Bar',
    pieGroup: 'Pie',
    lineGroup: 'Line',
    scatterGroup: 'X Y (Scatter)',
    areaGroup: 'Area',
    histogramGroup: 'Histogram',
    groupedColumnTooltip: 'Grouped',
    stackedColumnTooltip: 'Stacked',
    normalizedColumnTooltip: '100% Stacked',
    groupedBarTooltip: 'Grouped',
    stackedBarTooltip: 'Stacked',
    normalizedBarTooltip: '100% Stacked',
    pieTooltip: 'Pie',
    doughnutTooltip: 'Doughnut',
    lineTooltip: 'Line',
    groupedAreaTooltip: 'Area',
    stackedAreaTooltip: 'Stacked',
    normalizedAreaTooltip: '100% Stacked',
    scatterTooltip: 'Scatter',
    bubbleTooltip: 'Bubble',
    histogramTooltip: 'Histogram',
    noDataToChart: 'No data available to be charted.',
    pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.',
    chartSettingsToolbarTooltip: 'Menu',
    chartLinkToolbarTooltip: 'Linked to Grid',
    chartUnlinkToolbarTooltip: 'Unlinked from Grid',
    chartDownloadToolbarTooltip: 'Download Chart',

    // ARIA
    ariaHidden: 'hidden',
    ariaVisible: 'visible',
    ariaChecked: 'checked',
    ariaUnchecked: 'unchecked',
    ariaIndeterminate: 'indeterminate',
    ariaColumnSelectAll: 'Toggle Select All Columns',
    ariaInputEditor: 'Input Editor',
    ariaDateFilterInput: 'Date Filter Input',
    ariaFilterInput: 'Filter Input',
    ariaFilterColumnsInput: 'Filter Columns Input',
    ariaFilterValue: 'Filter Value',
    ariaFilterFromValue: 'Filter from value',
    ariaFilterToValue: 'Filter to value',
    ariaFilteringOperator: 'Filtering Operator',
    ariaColumnToggleVisibility: 'column toggle visibility',
    ariaColumnGroupToggleVisibility: 'column group toggle visibility',
    ariaRowSelect: 'Press SPACE to select this row',
    ariaRowDeselect: 'Press SPACE to deselect this row',
    ariaRowToggleSelection: 'Press Space to toggle row selection',
    ariaRowSelectAll: 'Press Space to toggle all rows selection',
    ariaSearch: 'Search',
    ariaSearchFilterValues: 'Search filter values',
  },
  ParentsScheduleModal: {
    Close: 'Close',
    'Schedule For Parents: date': 'Schedule For Parents: {{date}}',
  },
  SummariesPage: {
    'Service Summaries': 'Service Summaries',
    'Add New Summary': 'Add New Summary',
    Reload: 'Reload',
    Edit: 'Edit',
    Customer: 'Customer',
    Service: 'Service',
    Worker: 'Worker',
    'Created At': 'Created At',
    'Modified At': 'Modified At',
  },
  SummaryDetailsPage: {
    'Service Summary': 'Service Summary',
    Reload: 'Reload',
    'Created By': 'Created By',
    'Created At': 'Created At',
    'Modified At': 'Modified At',
    'Edit Summary': 'Edit Summary',
  },
  SummaryFormUI: {
    'Customer is required': 'Customer is required',
    'Service is required': 'Service is required',
    'Summary is required': 'Summary is required',
    Customer: 'Customer',
    Service: 'Service',
    Summary: 'Summary',
    Save: 'Save',
  },
  AddOrEditSummaryPage: {
    'Edit Service Summary': 'Edit Service Summary',
    'Add Service Summary': 'Add Service Summary',
  },
  TwoFactorAuthForm: {
    'Please enter code from the authenticator app': 'Please enter code from the authenticator app',
    '2FA Code is 6 characthers': '2FA Code is 6 characthers',
    'Code from App': 'Code from App',
    Code: 'Verification Code',
    Cancel: 'Cancel',
    'Enable 2FA': 'Enable 2FA',
    'Two factor authentication has been successfully enabled!':
      'Two factor authentication has been successfully enabled!',
  },
};
