import {Translations} from 'react-translate';

import {english} from './translations-eng';
import {estonian} from './translations-est';

export const getTranslations = (culture: string): Translations => {
  switch (culture) {
    default:
    case 'et':
    case 'et-ee':
      return estonian;

    case 'en':
    case 'en-us':
      return english;
  }
};
