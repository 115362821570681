import {Dispatch} from 'redux';
import {AxiosInstance} from 'axios';

import {
  COMMON_CONFIG_LOADED,
  COMMON_LOCALE_CHANGED,
  COMMON_LOCATIONS_LOADED,
  COMMON_REQUEST_ENDED,
  COMMON_REQUEST_STARTING,
  COMMON_SIDEBAR_STATE_CHANGED,
  ConfigLoadedAction,
  LocationsLoadedAction,
  RequestEndedAction,
  RequestStartingAction,
  RootState,
  SidebarToggleAction,
  UiLocaleChangedAction,
} from '../types';
import {Location} from '../../common/types';

export const requestStarting = (moduleName: string): RequestStartingAction => ({
  type: COMMON_REQUEST_STARTING,
  module: moduleName,
});

export const requestEnded = (): RequestEndedAction => ({
  type: COMMON_REQUEST_ENDED,
});

export const switchLanguage = (newLocale: string): UiLocaleChangedAction => ({
  type: COMMON_LOCALE_CHANGED,
  locale: newLocale,
});

const configLoaded = (config: Record<string, any>): ConfigLoadedAction => ({
  type: COMMON_CONFIG_LOADED,
  config,
});

const locationsLoaded = (locations: Location[]): LocationsLoadedAction => ({
  type: COMMON_LOCATIONS_LOADED,
  locations,
});

export const toggleSidebar = (isOpen: boolean): SidebarToggleAction => ({
  type: COMMON_SIDEBAR_STATE_CHANGED,
  isOpen,
});

export const loadConfig = () => (dispatch: Dispatch, state: RootState, api: AxiosInstance) => {
  dispatch(requestStarting('loadConfig'));
  api
    .get<Record<string, any>>('/api/core/config')
    .then(response => {
      dispatch(configLoaded(response.data));
    })
    .finally(() => dispatch(requestEnded()));
};

export const loadLocations = () => (dispatch: Dispatch, state: RootState, api: AxiosInstance) => {
  dispatch(requestStarting('loadLocations'));
  api
    .get<Location[]>('/api/core/locations')
    .then(response => {
      dispatch(locationsLoaded(response.data));
    })
    .finally(() => dispatch(requestEnded()));
};
