import {AxiosRequestConfig} from 'axios';
import {EventInput} from '@fullcalendar/react';

export type Modify<T, R> = Omit<T, keyof R> & R; // For overriding types

export enum DaysOfWeek {
  'P' = 0,
  'E',
  'T',
  'K',
  'N',
  'R',
  'L',
}

export type SidebarMenuItem = {
  id: number;
  name: string;
  icon: string;
  exact: boolean;
  target?: string;
  requireAdmin?: boolean;
  children?: SidebarMenuItem[];
};

// Models
export type UserMini = {
  id: number;
  fullName: string;
};

export type User = UserMini & {
  username: string;
  email: string;
  code: string;
  isAdmin: boolean;
  color?: string;
  role?: string;
  locale?: string;
  otpEnabled: boolean;
};

export type LocationPartial = {
  id: number;
  name: string;
};

export type Location = LocationPartial & {
  address: string;
  cityOrVillage: string;
  county: string;
  postalCode: number;
};

export type ServiceMini = {
  id: number;
  name: string;
};

export type Service = ServiceMini & {
  code: string;
  price: number;
  role?: string;
};

export type ServiceAvailability = {
  id: number;
  location: Location;
  service: Service;
  dayOfWeek: DaysOfWeek;
};

export type ServiceProvider = {
  id: number;
  worker: User;
  service: Service;
  location: Location;
};

export type CustomerContact = {
  id: number;
  fullName: string;
  phone: string;
  email: string;
  type: string;
  remarks?: string;
};

export type CustomerService = {
  id: number;
  service: Service;
  amountPerYear: number;
  objectives: string;
  recommendations?: string;
};

export type CustomerAvailability = {
  id: number;
  dayOfWeek: DaysOfWeek;
  since: string;
  until: string;
};

export enum CustomerStatus {
  Inactive = 0,
  Active = 1,
  Terminated = -1,
}

export type CustomerMini = {
  id: number;
  fullName: string;
};

export type CustomerPartial = CustomerMini & {
  status: CustomerStatus;
  amountPerPeriod: number;
};

export type CustomerFile = {
  id: number;
  document: string;
  documentHash: string;
  dateAdded: string;
  type: string;
};

export type RehaPlan = {
  planNo: string;
  validFrom: string;
  validUntil: string;
  document?: string;
  documentHash?: string;
  services: CustomerService[];
};

export type TransferLetter = {
  letterNo: string;
  validFrom: string;
  validUntil: string;
  issuer: string;
  amount: number;
};

export type Customer = CustomerPartial & {
  age: string;
  socialSecurityNo: string;
  transferLetter?: TransferLetter;
  rehaPlan?: RehaPlan;
  disabilityValidFrom?: string;
  disabilityValidUntil?: string;
  disabilityLevel: string;
  disabilityType: string;
  diagnosis?: string;
  location: Location;
  files: CustomerFile[];
  contacts: CustomerContact[];
  availabilities: CustomerAvailability[];
};

export type RehaEvent = Modify<
  EventInput,
  {
    workers: User[];
    location: LocationPartial;
    customers: CustomerPartial[];
    reportAvailable: boolean;
    isGroup: boolean;
  }
>;

export type WorkerService = {
  id: number;
  service: Service;
  location: Location;
};

export type Worker = User & {
  services: WorkerService[];
};

export type ServiceReport = {
  id?: number;
  eventId: number;
  customer: CustomerPartial;
  service: CustomerService;
  provider: ServiceProvider;
  amount?: number;
  date: string;
  objectives?: string;
  description?: string;
  remarks?: string;
  isWritable: boolean;
  hasReport: boolean;
};

export type ServiceReportMini = {
  id?: number;
  eventId: number;
  customerId: number;
  customerName: string;
  serviceName: string;
  workerId: number;
  workerName: string;
  locationName: string;
  amount?: number;
  date: string;
  isWritable: boolean;
  hasReport: boolean;
};

export type RehaAlert = {
  id: number;
  title: string;
  value: string;
  customer: CustomerPartial;
};

// Forms
export type LoginForm = {
  username: string;
  password: string;
  code: string;
};

export type PasswordChangeForm = {
  oldPassword: string;
  newPassword: string;
  repeatNewPassword: string;
  code: string;
};

export type EventStructure = {
  worker: User[];
  customer: Customer[];
  service: Service[];
};

export type EventForm = EventStructure & {
  start: Date;
  end: Date;
  location: LocationPartial[];
};

export type GroupEventForm = {
  start: Date;
  end: Date;
  location: LocationPartial[];
  groups: EventStructure[];
};

export type ServiceReportForm = {
  id?: number;
  user: string;
  isGroup: boolean;
  amount: number;
  objectives: string;
  description: string;
  remarks?: string;
};

export type ClearEventsForm = {
  date?: Date;
};

export type SelectedItem = {
  id: number;
  selected: boolean;
};

export type SelectedItemsForm = {
  selected: SelectedItem[];
  type: 'one' | 'multi';
};

export type CustomerServiceHours = {
  customer: CustomerPartial;
  service: CustomerService;
  hoursByMonth: number[];
  hoursTotal: number;
  hoursUsed: number;
  hoursDelta: number;
  total: number;
  totalUsed: number;
  totalDelta: number;
};

export type ScheduleForParents = {
  date: string;
  service: string;
};

export type ServiceSummary = {
  id: number;
  customer: CustomerMini;
  service: ServiceMini;
  worker: UserMini;
  createdAt: string;
  modifiedAt?: string;
  summary: string;
};

export type SummaryForm = {
  customer: CustomerMini[];
  service: ServiceMini[];
  summary: string;
};

export type Enable2faForm = {
  code: string;
};

// Special
export type AuthResponse = {
  access: string;
  refresh: string;
};

export type AxiosRequestConfigEx = AxiosRequestConfig & {
  _retry?: boolean;
};
