import {Translations} from 'react-translate';

export const estonian: Translations = {
  locale: 'et',
  AdminLayout: {
    Logout: 'Logi välja',
    Profile: 'Profiil',
    Admin: 'Admin',
    Error: 'Viga',
    Dashboard: 'Töölaud',
    'Service Reports': 'Teenuse raportid',
    'en-us': 'English',
    'et-ee': 'Eesti keel',
    Customers: 'Kliendid',
    Alerts: 'Alerdid',
    Reports: 'Raportid',
    'Service Hours': 'Teenustunnid',
    'Service Summaries': 'Teenuste kokkuvõtted',
    '2FA now available! Make your account more secure and activate 2FA!':
      'Kaheastmeline audentimine on nüüd saadaval! Muuda oma konto turvalisemaks aktiveerides kaheastmelise audentimise!',
    'Go to activate': 'Aktiveerima',
    'Loading...': 'Laen andmeid...',
  },
  Sidebar: {
    'Main Menu': 'Peamenüü',
  },
  ProfilePage: {
    'My Profile': 'Minu profiil',
    Administrator: 'Administraator',
    Worker: 'Töötaja',
    Details: 'Põhiandmed',
    'Change Password': 'Muuda parooli',
    Username: 'Kasutajanimi',
    'E-mail Address': 'E-posti aadress',
    'Color in Schedule': 'Värv graafikus',
    'Preferred Language': 'Eelistatud keel',
    'en-us': 'English',
    'et-ee': 'Eesti keel',
    Security: 'Turvalisus',
    '2FA Enabled?': '2FA aktiveeritud?',
    Yes: 'Jah',
    No: 'Ei',
    'Disable 2FA': 'Deaktiveeri 2FA',
    'Two factor authentication has been successfully disabled!': 'Kaheastmeline audentimine on edukalt deaktiveeritud!',
    'Are you sure you want to disable 2FA?': 'Oled sa kindel, et tahad kaheastmelise audentimise välja lülitada?',
    'Current Password': 'Kehtiv parool',
    Disable: 'Deaktiveeri',
    Cancel: 'Katkesta',
    Edit: 'Muuda',
    'Edit Profile': 'Muuda profiili',
    'First Name': 'Eesnimi',
    'Last Name': 'Perenimi',
    English: 'Inglise keel',
    Estonian: 'Eesti keel',
    'Valid E-mail Address required': 'Korrektne e-posti aadress on vajalik',
    'E-mail Address is required': 'E-posti aadress on kohustuslik',
    'First Name is required': 'Eesnimi on kohustuslik',
    'Last Name is required': 'Perenimi on kohustuslik',
    'Max 150 characters': 'Maksimaalselt 150 tähemärki',
    'Profile Updated': 'Profiil uuendatud',
    'Your changes have been successfully saved!': 'Sinu muudatused on edukalt salvestatud!',
    'No color': '- Pole valitud -',
    'No locale': '- Pole eelistust -',
    '2FA Code from the Authenticator App is required': '2FA kood Authenticator App-st on vajalik',
    '2FA Code is 6 characthers': '2FA kood on 6 numbirt',
    'Current Password is required': 'Kehtiv parool on vajalik',
    '2FA Code': '2FA kood',
    '2FA Code from Authenticator App': '2FA kood Authenticator App-st',
  },
  PasswordForm: {
    'Old Password is required': 'Palun sisesta kehtiv parool',
    'New Password is required': 'Palun sisesta uus parool',
    'New password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 symbol out of {{symbols}}':
      'Uus parool peab sisaldama ühte suurt tähte, ühte väiketähte, ühte numbit ja ühte sümbolit nendest: {{symbols}}',
    'Repeat the new password': 'Korda uut parooli',
    'Passwords must match': 'Uus parool ja tema kordus peavad kokku langema',
    'Old Password': 'Kehtiv parool',
    'New Password': 'Uus parool',
    'Repeat New Password': 'Uue parooli kordus',
    'Change Password': 'Muuda parooli',
    'Your password has been successfully changed': 'Sinu parool on edukalt vahetatud!',
    'New password should be at least 8 characters': 'Uus parool peab olema vähemalt 8 tähemärki',
    '2FA Code from Authenticator App is required': '2FA kood Authenticator App-st on vajalik',
    '2FA Code is 6 characthers': '2FA kood on 6 numbrit',
    '2FA Code': '2FA kood',
  },
  DashboardPage: {
    Dashboard: 'Töölaud',
    Reload: 'Värskenda',
    'Print Schedule': 'Prindi graafik',
    'Schedule For Parents': 'Graafik lapsevanematele',
  },
  WorkerCalendar: {
    day: 'Päev',
    week: 'Nädal',
    month: 'Kuu',
    today: 'Täna',
    prev: 'Eelmine',
    next: 'Järgmine',
  },
  AdminCalendar: {
    day: 'Päev',
    week: 'Nädal',
    month: 'Kuu',
    today: 'Täna',
    prev: 'Eelmine',
    next: 'Järgmine',
    Delete: 'Kustuta',
    'Are you sure you want to delete the event xxx?': 'Oled sa kindel, et sa soovid kustutada sündmuse {{name}}?',
    'Are you adding a GROUP appointment?': 'Kas sa soovid lisada GRUPI seanssi?',
  },
  EventManagementService: {
    'Wrong timeslot': 'Vale ajavahemik',
    'We can plan only ahead of time.': 'Planeerida saab vaid ajas edasi.',
    'No services': 'Teenused',
    'No providers': 'Töötajad',
    'There are no services scheduled for dayOfWeek!': 'Ühtegi teenust ei ole defineeritud päevale {{dayOfWeek}}!',
    'There are no providers for any of those locations!':
      'Mitte ühtegi töötajat ei ole üheski neis kohtades defineeritud!',
  },
  EventFormUI: {
    'Start Date': 'Seansi algus',
    'End Date': 'Seansi lõpp',
    Customer: 'Klient',
    Specialist: 'Spetsialist',
    Location: 'Asukoht',
    Service: 'Teenus',
    'Reha Plan Missing': 'Aktiivne reha plaan puudub',
    'There is no active reha plan for date': 'Valitud kliendil puudub kuupäeval {{date}} aktiivne reha plaan!',
  },
  GroupEventFormUI: {
    'Start Date': 'Seansi algus',
    'End Date': 'Seansi lõpp',
    Customer: 'Klient',
    Specialist: 'Spetsialist',
    Location: 'Asukoht',
    Service: 'Teenus',
    'Add Row': 'Lisa rida',
  },
  ConfirmationModal: {
    'Confirm Action': 'Kinnita toiming',
    Yes: 'Jah',
    No: 'Ei',
  },
  EventModal: {
    'Event start date is required': 'Seansi alguse kuupäev on vajalik',
    'Minimum start date is current time': 'Käesolev kuupäev või suurem',
    'Event end date is required': 'Seansi lõppemise kuupäev on vajalik',
    'Event end date must be greater than or equal to start date':
      'Seansi lõppemise kuupäev peab olema suurem või võrdne alguse kuupäevaga',
    'At least one customer is required': 'Klient peab olema valitud',
    'At least one worker is required': 'Spetsialist peab olema valitud',
    'At least one location is required': 'Asukoht peab olema valitud',
    'Add New Service Event': 'Uue seansi lisamine',
    'At least on service is required': 'Teenus peab olema valitud',
  },
  GroupEventModal: {
    'Event start date is required': 'Seansi alguse kuupäev on vajalik',
    'Minimum start date is current time': 'Käesolev kuupäev või suurem',
    'Event end date is required': 'Seansi lõppemise kuupäev on vajalik',
    'Event end date must be greater than or equal to start date':
      'Seansi lõppemise kuupäev peab olema suurem või võrdne alguse kuupäevaga',
    'At least one location is required': 'Asukoht peab olema valitud',
    'At least one customer is required': 'Klient peab olema valitud',
    'At least one worker is required': 'Spetsialist peab olema valitud',
    'At least on service is required': 'Teenus peab olema valitud',
    'At least one group is required': 'Vähemalt üks grupp peab olema defineeritud',
    'Add New Group Service Event': 'Uue grupiseansi lisamine',
  },
  FbStyleLoader: {
    'Executing...': 'Töötan...',
  },
  Loader: {
    'Loading...': 'Laen andmeid...',
  },
  ModalWithForm: {
    Save: 'Salvesta',
    Close: 'Sulge',
  },
  DatePickerField: {
    Time: 'Kellaaeg',
  },
  TypeaheadField: {
    Duplication: 'Duplikaat',
    'Type in and select a value: ': 'Sisesta väärtus ja siis vali see: ',
    'Duplicate item not added: {{duplicate}}': 'Duplikaati ei lisatud: {{duplicate}}',
  },
  ErrorPage: {
    'Go Back': 'Tagasi',
    'Server Error': 'Viga serveri poolel',
    "It's not you, it's me.": 'Asi ei ole sinus, usu mind.',
  },
  Page404: {
    Home: 'Avaleht',
    'Page not found.': 'Sellist lehte ei leitud.',
  },
  LoginPage: {
    'Username is required': 'Palun sisesta kasutajanimi',
    'Password is required': 'Palun sisesta parool',
    'Sign In': 'Logi sisse',
    Username: 'Kasutajanimi',
    Password: 'Parool',
    '2FA Code from Authenticator App is required': '2FA kood Authenticator App-st on vajalik',
    '2FA Code is 6 characthers': '2FA kood on 6 numbirt',
    '2FA Code': '2FA kood',
    '2FA Code from Authenticator App': '2FA kood Authenticator App-st',
  },
  AddEditReportPage: {
    'Service Report': 'Teenuse osutamise raport',
    Amount: 'Teenuse hulk (tundide arv)',
    Objectives: 'Teenuse mõju isikule',
    Description: 'Teenuse sisu',
    Remarks: 'Märkused',
    'Save Report': 'Salvesta raport',
    'For Worker': 'Teenuse osutaja',
    'Objectives of the session are required': 'Palun sisesta sessiooni eesmärgid.',
    'Try to squeeze into xxx chars.': 'Palun ürita mahtuda {{count}} tähemärgi piiresse.',
    'Session description is required': 'Palun kirjelda sessiooni käigus tehtut/saavutatut.',
    Worker: 'Spetsialist(id)',
  },
  ClearEventsModal: {
    'Clear Old events': 'Vanade sündmuste kustutamine',
    'Date is required': 'Palun vali kuupäev',
    'Only older than dday events': 'Kustutada saab vaid sündmusi, mis on vanemad kui {{date}}',
  },
  ClearEventsFormUI: {
    Date: 'Kuupäev',
    'Events removed will be the ones less than given date':
      'Sündmused, mis kustutatakse, on need, mis on vanemad kui valitud kuupäev.',
  },
  GenerateEventsModal: {
    'Generate Events': 'Graafiku genereerimine',
    'Positive numbers only': 'Positiivsed täisarvud ainult',
    'Month is required': 'Palun sisesta kuu',
    'Minimum month number is 1': 'Väikseim kuu number on 1',
    'Maximum month number is 12': 'Suurim kuu number on 12',
    'Year is required': 'Palun sisesta aasta',
    'Current year is minimum': 'Käesolev aasta on väikseim, mida saab sisestada',
    'Future dates please': 'Vähemalt järgmine kuu palun',
  },
  GenerateEventsFormUI: {
    Month: 'Kuu',
    Year: 'Aasta',
  },
  ReportsPage: {
    'Report is missing!': 'Teenuse osutamise raport on sisestamata!',
    Date: 'Kuupäev',
    Customer: 'Klient',
    Service: 'Teenus',
    Location: 'Asukoht',
    Worker: 'Spetsialist',
    Amount: 'Hulk',
    Objectives: 'Otsesed eesmärgid',
    Description: 'Teenuse sisu',
    Remarks: 'Märkused',
    'Service Reports': 'Teenuse osutamise raportid',
    Year: 'Aasta',
    Month: 'Kuu',
    'Export Reports': 'Raportite eksport',
    Reload: 'Lae uuesti',
    'Export My Reports': 'Minu raportite eksport',
    'Create Report Without Data': 'Lisa tühi raport vaid tundidega',
    'Hours of the event #id have been added to report': 'Sündmuse #{{id}} tunnid on raportisse lisatud.',
    'Are you sure you would like to add just hours for this report?':
      'Oled sa kindel, et soovid lisada vaid tunnid sellele raportile?',
    Edit: 'Muuda raportit',
    'Add Empty Report': 'Lisa tühi raport',
  },
  ExportReportsModal: {
    'Select Reports to Export': 'Vali raportid, mida eksportida',
    'At least one report should be selected': 'Vähemalt üks raport peab olema valitud.',
    'After exporting the reports, you cannot edit those reports anymore!':
      'Peale raportite salvestamist Word dokumenti sa ei saa neid enam siin muuta ja uuesti salvestada!',
    'Select All': 'Märgi kõik',
    'Unselect All': 'Eemalda kõik',
    'Select individual customers': 'Vali kliendid ükshaaval...',
    'Select Events to Add an Empty Report': 'Valige graafikud, millele soovite lisada tühja raporti',
  },
  ReportDetailsPage: {
    'Report Error': 'Viga raporti andmete laadmisel',
    'Report with ID xxx not found': 'Raportit ID-ga #{{id}} ei leitud',
    'Service Report Details': 'Teenuse osutamise raport',
    Objectives: 'Teenuse mõju isikule',
    Description: 'Teenuse sisu',
    Remarks: 'Märkused',
    Reload: 'Värskenda',
    Worker: 'Spetsialist',
  },
  CustomersPage: {
    'Full Name': 'Ees- ja perenimi',
    'Service Location': 'Teenuse saamise koht',
    'Social Security No.': 'Isikukood',
    'Transfer Letter No.': 'Suunamiskirja nr.',
    'Transfer Letter Valid Until': 'Suunamiskirja kehtivus kuni',
    'Disability Valid Until': 'Puudeotsuse kehtivus kuni',
    'Reha Plan No.': 'Rehaplaani nr.',
    'Reha Plan Valid Until': 'Rehaplaani kehtivus kuni',
    Customers: 'Kliendid',
    Reload: 'Värskenda',
    Age: 'Vanus',
  },
  CustomerDetailsPage: {
    'Full Name': 'Ees- ja perenimi',
    'Service Location': 'Teenuse saamise koht',
    'Social Security No.': 'Isikukood',
    'Transfer Letter No.': 'Suunamiskirja nr.',
    'Transfer Letter Valid Until': 'Suunamiskirja kehtivus kuni',
    'Disability Valid Until': 'Puudeotsuse kehtivus kuni',
    'Reha Plan No.': 'Rehaplaani nr.',
    'Reha Plan Valid From': 'Rehaplaani kehtivus alates',
    'Reha Plan Valid Until': 'Rehaplaani kehtivus kuni',
    'Customer Details': 'Kliendi andmed',
    Reload: 'Värskenda',
    Contacts: 'Seadusliku esindaja kontaktid',
    Services: 'Teenused',
    Availabilities: 'Saadavus',
    Name: 'Nimi',
    Email: 'E-post',
    Phone: 'Telefon',
    Code: 'Kood',
    'Amount (per year)': 'Kogus (aasta kohta)',
    Objectives: 'Teenuse üldeesmärk',
    Recommendations: 'Tegevused eesmärkide saavutamiseks',
    'Day of Week': 'Nädalapäev',
    Since: 'Alates',
    Until: 'Kuni',
    E: 'esmaspäeviti',
    T: 'teisipäeviti',
    K: 'kolmapäeviti',
    N: 'neljapäeviti',
    R: 'reedeti',
    L: 'laupäeviti',
    P: 'pühapäeviti',
    'Download Reha Plan Document': 'Lae rehaplaan alla',
    Threshold: 'Kasutatud',
    Active: 'Aktiivne',
    Inactive: 'Deaktiivne',
    Terminated: 'Lõpetatud',
    Diagnosis: 'Diagnoos',
    Status: 'Staatus',
    Files: 'Dokumendid',
    Document: 'Dokument',
    Added: 'Lisatud',
    'Download Document': 'Lae dokument alla',
    Age: 'Vanus',
    'Preview Reha Plan Document': 'Vaata Rehaplaani',
    'Preview Document': 'Vaata dokumenti',
    'Transfer Letter Valid From': 'Suunamiskirja kehtivus alates',
    'Transfer Letter Issuer': 'Suunamiskirja väljastaja',
    'Disability Valid From': 'Puudeotsuse kehtivus alates',
    'Disability Level': 'Puude raskusaste',
    'Disability Type': 'Puudeliik',
    medium: 'keskmine',
    severe: 'raske',
    deep: 'sügav',
    null: '',
    Type: 'Tüüp',
    Remarks: 'Märkused',
    parent: 'Lapsevanem',
    kov: 'KOV',
    other: 'Muu',
    'Sum Per Period': 'Summa suunamiskirja perioodiks',
    Role: 'Roll',
  },
  AlertsPage: {
    Customer: 'Klient',
    Alert: 'Hoiatus',
    Value: 'Aegumise kuupäev',
    Alerts: 'Hoiatused',
    Reload: 'Värskenda',
  },
  MissedEventsModal: {
    'Events Not Generated': 'Vead graafiku koostamisel',
    Close: 'Sulge',
    Customer: 'Klient',
    Service: 'Teenus',
    Worker: 'Spetsialist',
    Explanation: 'Selgitus',
  },
  CustomerServiceHoursPage: {
    'Customer Service Hours Report': 'Teenustundide Report',
    Reload: 'Värskenda',
    Customer: 'Klient',
    Service: 'Teenus',
    Jan: 'Jaan',
    Feb: 'Veebr',
    Mar: 'Mar',
    Apr: 'Apr',
    May: 'Mai',
    Jun: 'Jun',
    Jul: 'Jul',
    Aug: 'Aug',
    Sep: 'Sep',
    Oct: 'Okt',
    Nov: 'Nov',
    Dec: 'Dets',
    'Total Hours': 'Kokku (h)',
    'Hours Used': 'Kasutatud (h)',
    'Hours Spare': 'Kasutada (h)',
    'Total Money': 'Kokku (€)',
    'Money Spent': 'Kasutatud (€)',
    'Money Spare': 'Kasutada (€)',
  },
  FilePreviewModal: {
    'File Preview': 'Dokumendi eelvaade',
    Download: 'Lae alla',
  },
  AgGrid: {
    // Set Filter
    selectAll: '(Vali kõik)',
    selectAllSearchResults: '(Vali kõik otsingutulemused)',
    searchOoo: 'Otsi...',
    blanks: '(Tühjad)',
    noMatches: 'Vasted puuduvad',

    // Number Filter & Text Filter
    filterOoo: 'Filtreeri...',
    equals: 'Võrdne',
    notEqual: 'Ei ole võrdne',
    empty: 'Vali üks',

    // Number Filter
    lessThan: 'Väiksem kui',
    greaterThan: 'Suurem kui',
    lessThanOrEqual: 'Väiksem kui või võrdne',
    greaterThanOrEqual: 'Suurem kui või võrdne',
    inRange: 'Vahemikus',
    inRangeStart: 'alates',
    inRangeEnd: 'kuni',

    // Text Filter
    contains: 'Sisaldab',
    notContains: 'Ei sisalda',
    startsWith: 'Algab',
    endsWith: 'Lõpeb',

    // Date Filter
    dateFormatOoo: 'yyyy-mm-dd',

    // Filter Conditions
    andCondition: 'AND',
    orCondition: 'OR',

    // Filter Buttons
    applyFilter: 'Rakenda',
    resetFilter: 'Tühista',
    clearFilter: 'Tühjenda',
    cancelFilter: 'Katkesta',

    // Filter Titles
    textFilter: 'Tekstifilter',
    numberFilter: 'Numbrifilter',
    dateFilter: 'Kuupäeva filter',
    setFilter: 'Valikfilter',

    // Side Bar
    columns: 'Tulbad',
    filters: 'Filtrid',

    // columns tool panel
    pivotMode: 'Pivot Mode',
    groups: 'Row Groups',
    rowGroupColumnsEmptyMessage: 'Drag here to set row groups',
    values: 'Values',
    valueColumnsEmptyMessage: 'Drag here to aggregate',
    pivots: 'Column Labels',
    pivotColumnsEmptyMessage: 'Drag here to set column labels',

    // Header of the Default Group Column
    group: 'Group',

    // Other
    loadingOoo: 'Laen andmeid...',
    noRowsToShow: 'Andmed puuduvad',
    enabled: 'Enabled',

    // Menu
    pinColumn: 'Pin Column',
    pinLeft: 'Pin Left',
    pinRight: 'Pin Right',
    noPin: 'No Pin',
    valueAggregation: 'Value Aggregation',
    autosizeThiscolumn: 'Autosize This Column',
    autosizeAllColumns: 'Autosize All Columns',
    groupBy: 'Group by',
    ungroupBy: 'Un-Group by',
    resetColumns: 'Reset Columns',
    expandAll: 'Expand All',
    collapseAll: 'Close All',
    copy: 'Kopeeri',
    ctrlC: 'Ctrl+C',
    copyWithHeaders: 'Kopeeri koos tulba pealkirjadega',
    paste: 'Kleebi',
    ctrlV: 'Ctrl+V',
    export: 'Eksport',
    csvExport: 'Ekspordi CSV faili',
    excelExport: 'Ekspordi Excelisse',

    // Enterprise Menu Aggregation and Status Bar
    sum: 'Sum',
    min: 'Min',
    max: 'Max',
    none: 'None',
    count: 'Count',
    avg: 'Average',
    filteredRows: 'Filtered',
    selectedRows: 'Selected',
    totalRows: 'Total Rows',
    totalAndFilteredRows: 'Rows',
    more: 'More',
    to: '-',
    of: '/',
    page: 'Leht',
    nextPage: 'Järgmine leht',
    lastPage: 'Viimane leht',
    firstPage: 'Esimene leht',
    previousPage: 'Eelmine leht',

    // Pivoting
    pivotColumnGroupTotals: 'Kokku',

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
    pivotChart: 'Pivot Chart',
    chartRange: 'Chart Range',

    columnChart: 'Column',
    groupedColumn: 'Grouped',
    stackedColumn: 'Stacked',
    normalizedColumn: '100% Stacked',

    barChart: 'Bar',
    groupedBar: 'Grouped',
    stackedBar: 'Stacked',
    normalizedBar: '100% Stacked',

    pieChart: 'Pie',
    pie: 'Pie',
    doughnut: 'Doughnut',

    line: 'Line',

    xyChart: 'X Y (Scatter)',
    scatter: 'Scatter',
    bubble: 'Bubble',

    areaChart: 'Area',
    area: 'Area',
    stackedArea: 'Stacked',
    normalizedArea: '100% Stacked',

    histogramChart: 'Histogram',

    // Charts
    pivotChartTitle: 'Pivot Chart',
    rangeChartTitle: 'Range Chart',
    settings: 'Settings',
    data: 'Data',
    format: 'Format',
    categories: 'Categories',
    defaultCategory: '(None)',
    series: 'Series',
    xyValues: 'X Y Values',
    paired: 'Paired Mode',
    axis: 'Axis',
    navigator: 'Navigator',
    color: 'Color',
    thickness: 'Thickness',
    xType: 'X Type',
    automatic: 'Automatic',
    category: 'Category',
    number: 'Number',
    time: 'Time',
    xRotation: 'X Rotation',
    yRotation: 'Y Rotation',
    ticks: 'Ticks',
    width: 'Width',
    height: 'Height',
    length: 'Length',
    padding: 'Padding',
    spacing: 'Spacing',
    chart: 'Chart',
    title: 'Title',
    titlePlaceholder: 'Chart title - double click to edit',
    background: 'Background',
    font: 'Font',
    top: 'Top',
    right: 'Right',
    bottom: 'Bottom',
    left: 'Left',
    labels: 'Labels',
    size: 'Size',
    minSize: 'Minimum Size',
    maxSize: 'Maximum Size',
    legend: 'Legend',
    position: 'Position',
    markerSize: 'Marker Size',
    markerStroke: 'Marker Stroke',
    markerPadding: 'Marker Padding',
    itemSpacing: 'Item Spacing',
    itemPaddingX: 'Item Padding X',
    itemPaddingY: 'Item Padding Y',
    layoutHorizontalSpacing: 'Horizontal Spacing',
    layoutVerticalSpacing: 'Vertical Spacing',
    strokeWidth: 'Stroke Width',
    offset: 'Offset',
    offsets: 'Offsets',
    tooltips: 'Tooltips',
    callout: 'Callout',
    markers: 'Markers',
    shadow: 'Shadow',
    blur: 'Blur',
    xOffset: 'X Offset',
    yOffset: 'Y Offset',
    lineWidth: 'Line Width',
    normal: 'Normal',
    bold: 'Bold',
    italic: 'Italic',
    boldItalic: 'Bold Italic',
    predefined: 'Predefined',
    fillOpacity: 'Fill Opacity',
    strokeOpacity: 'Line Opacity',
    histogramBinCount: 'Bin count',
    columnGroup: 'Column',
    barGroup: 'Bar',
    pieGroup: 'Pie',
    lineGroup: 'Line',
    scatterGroup: 'X Y (Scatter)',
    areaGroup: 'Area',
    histogramGroup: 'Histogram',
    groupedColumnTooltip: 'Grouped',
    stackedColumnTooltip: 'Stacked',
    normalizedColumnTooltip: '100% Stacked',
    groupedBarTooltip: 'Grouped',
    stackedBarTooltip: 'Stacked',
    normalizedBarTooltip: '100% Stacked',
    pieTooltip: 'Pie',
    doughnutTooltip: 'Doughnut',
    lineTooltip: 'Line',
    groupedAreaTooltip: 'Area',
    stackedAreaTooltip: 'Stacked',
    normalizedAreaTooltip: '100% Stacked',
    scatterTooltip: 'Scatter',
    bubbleTooltip: 'Bubble',
    histogramTooltip: 'Histogram',
    noDataToChart: 'No data available to be charted.',
    pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.',
    chartSettingsToolbarTooltip: 'Menu',
    chartLinkToolbarTooltip: 'Linked to Grid',
    chartUnlinkToolbarTooltip: 'Unlinked from Grid',
    chartDownloadToolbarTooltip: 'Download Chart',

    // ARIA
    ariaHidden: 'hidden',
    ariaVisible: 'visible',
    ariaChecked: 'checked',
    ariaUnchecked: 'unchecked',
    ariaIndeterminate: 'indeterminate',
    ariaColumnSelectAll: 'Toggle Select All Columns',
    ariaInputEditor: 'Input Editor',
    ariaDateFilterInput: 'Date Filter Input',
    ariaFilterInput: 'Filter Input',
    ariaFilterColumnsInput: 'Filter Columns Input',
    ariaFilterValue: 'Filter Value',
    ariaFilterFromValue: 'Filter from value',
    ariaFilterToValue: 'Filter to value',
    ariaFilteringOperator: 'Filtering Operator',
    ariaColumnToggleVisibility: 'column toggle visibility',
    ariaColumnGroupToggleVisibility: 'column group toggle visibility',
    ariaRowSelect: 'Press SPACE to select this row',
    ariaRowDeselect: 'Press SPACE to deselect this row',
    ariaRowToggleSelection: 'Press Space to toggle row selection',
    ariaRowSelectAll: 'Press Space to toggle all rows selection',
    ariaSearch: 'Search',
    ariaSearchFilterValues: 'Search filter values',
  },
  ParentsScheduleModal: {
    Close: 'Sulge',
    'Schedule For Parents: date': 'Graafikud lapsevanematele saatmiseks: {{date}}',
  },
  SummariesPage: {
    'Service Summaries': 'Teenuste kokkuvõtted',
    'Add New Summary': 'Lisa uus kokkuvõte',
    Reload: 'Värskenda',
    Edit: 'Muuda',
    Customer: 'Klient',
    Service: 'Teenus',
    Worker: 'Esitaja',
    'Created At': 'Esitatud',
    'Modified At': 'Muudetud',
  },
  SummaryDetailsPage: {
    'Service Summary': 'Teenuse kokkuvõte',
    Reload: 'Värskenda',
    'Created By': 'Esitaja',
    'Created At': 'Esitatud',
    'Modified At': 'Muudetud',
    'Edit Summary': 'Muuda kokkuvõtet',
  },
  SummaryFormUI: {
    'Customer is required': 'Palun vali klient',
    'Service is required': 'Palun vali teenus',
    'Summary is required': 'Palun kirjuta kokkuvõte',
    Customer: 'Klient',
    Service: 'Teenus',
    Summary: 'Kokkuvõte',
    Save: 'Salvesta',
  },
  AddOrEditSummaryPage: {
    'Edit Service Summary': 'Muuda teenuse kokkuvõtet',
    'Add Service Summary': 'Lisa uus teenuse kokkuvõte',
  },
  TwoFactorAuthForm: {
    'Please enter code from the authenticator app': 'Palun sisestage kood authenticator app-st',
    '2FA Code is 6 characthers': '2FA kood on 6 numbrit',
    'Code from App': 'Kood app-st',
    Code: 'Kontrollkood',
    Cancel: 'Katkesta',
    'Enable 2FA': 'Aktiveeri 2FA',
    'Two factor authentication has been successfully enabled!': 'Kaheastmeline audentimine on edukalt aktiveeritud!',
  },
};
