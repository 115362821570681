import {Dispatch} from 'redux';
import axios, {AxiosError, AxiosInstance, AxiosResponse} from 'axios';

import {AuthResponse, AxiosRequestConfigEx} from './types';
import {loginDenied, loginFailure} from '../store/actions/authentication';

let instance: AxiosInstance | null = null;
let refreshTokenPromise: Promise<string> | null = null;

type RefreshRequest = {
  refresh: string | null;
};

const refresh = (): Promise<string> => {
  if (!instance) {
    return Promise.reject('No axios instance');
  }
  return instance
    .post<RefreshRequest, AxiosResponse<AuthResponse>>('/api/token/refresh', {
      refresh: sessionStorage.getItem('refresh'),
    })
    .then(response => {
      sessionStorage.setItem('access', response.data.access);
      sessionStorage.setItem('refresh', response.data.refresh);
      return response.data.access;
    });
};

const cleanup = () => {
  sessionStorage.removeItem('access');
  sessionStorage.removeItem('refresh');
  if (instance && instance.defaults.headers) {
    delete instance.defaults.headers.common['Authorization'];
  }
};

export const getLanguage = (): string => {
  const locale = localStorage.getItem('reha_locale');
  if (!locale) {
    return 'en';
  }
  if (locale.includes('-')) {
    return locale.split('-')[0];
  }
  return locale;
};

const NetworkService = {
  isAuthenticated: () => sessionStorage.getItem('access') !== null,
  getAxiosApi: (): AxiosInstance => {
    if (instance === null) {
      instance = axios.create({
        headers: {
          'Accept-Language': 'en-us',
          Accept: 'application/json',
        },
      });
      const token = sessionStorage.getItem('access');
      if (token && instance.defaults.headers) {
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      }
      const locale = localStorage.getItem('reha_locale');
      if (locale && instance.defaults.headers) {
        instance.defaults.headers.common['Accept-Language'] = locale;
      }
    }
    return instance;
  },
  setInterceptor: (dispatch: Dispatch<any>) => {
    instance?.interceptors.response.use(
      response => response,
      (error: AxiosError) => {
        switch (error.response?.status) {
          case 401:
            const originalRequest = error.config as AxiosRequestConfigEx;
            if (originalRequest.url?.includes('/api/token')) {
              cleanup();
              dispatch(loginFailure(['Your session has expired']));
              return Promise.reject(error);
            }
            if (!refreshTokenPromise) {
              refreshTokenPromise = refresh()
                .then(code => {
                  refreshTokenPromise = null;
                  return code;
                })
                .catch(err => Promise.reject(err));
            }
            return refreshTokenPromise.then(code => {
              originalRequest.headers!['Authorization'] = `Bearer ${code}`;
              instance!.defaults.headers!.common['Authorization'] = `Bearer ${code}`;
              return axios(originalRequest);
            });
          case 403:
            cleanup();
            dispatch(loginDenied());
            break;
          default:
            break;
        }
        return Promise.reject(error);
      }
    );
    instance?.interceptors.request.use(
      config => {
        const locale = getLanguage();
        config.url = `/${locale}${config.url}`;
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
  },
  setAuthentication: (token: string) => {
    if (instance && instance.defaults.headers) {
      instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
  },
  removeAuthentication: () => {
    if (instance && instance.defaults.headers) {
      delete instance.defaults.headers.common['Authorization'];
    }
  },
  setLocale: (newLocale: string) => {
    if (instance && instance.defaults.headers) {
      instance.defaults.headers.common['Accept-Language'] = newLocale;
    }
  },
};
export default NetworkService;
