import * as React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import * as Sentry from '@sentry/browser';
import ReduxToastr from 'react-redux-toastr';
import {BrowserTracing} from '@sentry/tracing';

import './index.css';

import App from './App';
import StaticApp from './StaticApp';
import {configureStore} from './store/store';
import {AppConfig, getAppConfig} from './common/Config';

import * as serviceWorker from './serviceWorker';

const initializeSentry = (config: AppConfig) => {
  Sentry.init({
    dsn: config.sentryDsn,
    environment: config.environment,
    release: config.version,
    ignoreErrors: ['Request aborted'],
    integrations: [new BrowserTracing()],
    tracesSampleRate: config.profilerRate,
  });
};

getAppConfig()
  .then(config => {
    if (config.sentryDsn) {
      initializeSentry(config);
    }
    ReactDOM.render(
      <React.StrictMode>
        <Provider store={configureStore()}>
          <ReduxToastr closeOnToastrClick={true} transitionIn={'fadeIn'} transitionOut={'fadeOut'} />
          <App />
        </Provider>
      </React.StrictMode>,
      document.getElementById('root')
    );
  })
  .catch(error => {
    ReactDOM.render(
      <React.StrictMode>
        <StaticApp error={error} />
      </React.StrictMode>,
      document.getElementById('root')
    );
  });

// If you want your store to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
