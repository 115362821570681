import {
  SERVICES_AVAILABILITIES_LOADED,
  SERVICES_EVENT_DATE_CHANGED,
  SERVICES_EVENT_LOCATION_CHANGED,
  SERVICES_EVENT_SERVICE_CHANGED,
  SERVICES_PROVIDERS_LOADED,
  ServicesActions,
  ServicesState,
} from '../types';

const initialState: ServicesState = {
  providers: [],
  availabilities: [],
};

export const servicesStateReducer = (state: ServicesState = initialState, action: ServicesActions): ServicesState => {
  switch (action.type) {
    case SERVICES_AVAILABILITIES_LOADED:
      return {...state, availabilities: [...action.availabilities]};

    case SERVICES_PROVIDERS_LOADED:
      return {...state, providers: [...action.providers]};

    case SERVICES_EVENT_DATE_CHANGED:
      return {...state, eventDate: action.date};

    case SERVICES_EVENT_LOCATION_CHANGED:
      return {...state, location: {...action.location}};

    case SERVICES_EVENT_SERVICE_CHANGED:
      return {...state, service: {...action.service}};

    default:
      return state;
  }
};
