import * as React from 'react';
import * as Sentry from '@sentry/browser';
import {Navigate} from 'react-router-dom';

import {User} from '../../common/types';

type Props = {
  user?: User;
  locale: string;
};

type State = {
  eventId?: string;
  error: boolean;
};

export class ErrorBoundary extends React.Component<Props, State> {
  /**
   *
   * Catches all errors from child components.
   * More info:
   * https://reactjs.org/docs/error-boundaries.html
   *
   */
  constructor(props: Props) {
    super(props);
    this.state = {error: false};
  }

  componentDidCatch(error: Error, errorInfo: Record<string, any>) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const sentryEventId = Sentry.captureException(error);
      this.setState({eventId: sentryEventId, error: true});
      Sentry.showReportDialog({
        eventId: this.state.eventId,
        lang: this.props.locale,
        user:
          this.props.user !== undefined
            ? {
                name: this.props.user.fullName,
                email: this.props.user.email,
              }
            : {name: '', email: ''},
      });
    });
  }

  render() {
    if (this.state.error) {
      return <Navigate to="/error" />;
    }
    return this.props.children;
  }
}
