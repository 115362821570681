import {Dispatch} from 'redux';
import {toastr} from 'react-redux-toastr';
import {NavigateFunction} from 'react-router-dom';
import {AxiosError, AxiosInstance, AxiosResponse} from 'axios';

import parseErrors from '../../common/functions';
import NetworkService from '../../common/NetworkService';
import {requestEnded, requestStarting, switchLanguage} from './common';
import {AuthResponse, Enable2faForm, LoginForm, PasswordChangeForm, User} from '../../common/types';
import {
  AUTH_DENIED,
  AUTH_FAILURE,
  AUTH_LOGOUT,
  AUTH_PROFILE,
  AuthDeniedAction,
  AuthFailureAction,
  AuthLogoutAction,
  ProfileSuccessAction,
  RootState,
} from '../types';

export const loginFailure = (messages: string[]): AuthFailureAction => ({
  type: AUTH_FAILURE,
  messages,
});

export const loginDenied = (): AuthDeniedAction => ({
  type: AUTH_DENIED,
});

const logoutSuccess = (): AuthLogoutAction => ({
  type: AUTH_LOGOUT,
});

const profileSuccess = (user: User): ProfileSuccessAction => ({
  type: AUTH_PROFILE,
  user,
});

export const fetchProfile =
  (startCommon: boolean = true) =>
  (dispatch: Dispatch, state: RootState, api: AxiosInstance) => {
    if (startCommon) {
      dispatch(requestStarting('fetchProfile'));
    }
    api
      .get<User>('/api/core/profile')
      .then(response => {
        dispatch(profileSuccess(response.data));
        if (response.data.locale) {
          dispatch(switchLanguage(response.data.locale));
          NetworkService.setLocale(response.data.locale);
          localStorage.setItem('reha_locale', response.data.locale);
        }
      })
      .finally(() => dispatch(requestEnded()));
  };

export const updateProfile =
  (
    form: {email: string; locale: string; firstName: string; lastName: string; verification: string},
    t: any,
    callback: VoidFunction
  ) =>
  (dispatch: Dispatch<any>, state: RootState, api: AxiosInstance) => {
    dispatch(requestStarting('updateProfile'));
    api
      .patch('/api/core/profile', {
        email: form.email,
        last_name: form.lastName,
        first_name: form.firstName,
        password: form.verification,
        preferred_language: form.locale ?? null,
      })
      .then(response => {
        dispatch(profileSuccess(response.data));
        toastr.success(t('Profile Updated'), t('Your changes have been successfully saved!'));
        if (callback) {
          callback();
        }
      })
      .catch((error: AxiosError) => parseErrors(error).then(errors => dispatch(loginFailure(errors))))
      .finally(() => dispatch(requestEnded()));
  };

export const login =
  (form: LoginForm, navigate: NavigateFunction, callback: VoidFunction) =>
  (dispatch: Dispatch<any>, state: RootState, api: AxiosInstance) => {
    dispatch(requestStarting('login'));
    api
      .post<LoginForm, AxiosResponse<AuthResponse>>(form.code ? '/api/token/verify' : '/api/token', form)
      .then(response => {
        if (response.status === 206 && callback) {
          callback();
          dispatch(requestEnded());
        } else {
          localStorage.setItem('reha_user', form.username);
          sessionStorage.setItem('access', response.data.access);
          sessionStorage.setItem('refresh', response.data.refresh);
          NetworkService.setAuthentication(response.data.access);
          dispatch(fetchProfile(false));
          dispatch(requestEnded());
          navigate('/');
        }
      })
      .catch((error: AxiosError) => {
        parseErrors(error).then(errors => {
          dispatch(loginFailure(errors));
          dispatch(requestEnded());
        });
      });
  };

type LogoutRequest = {
  refresh_token: string | null;
};

export const logout = () => (dispatch: Dispatch, _: RootState, api: AxiosInstance) => {
  dispatch(requestStarting('logout'));
  api
    .post<LogoutRequest, never>('/api/token/logout', {
      refresh_token: sessionStorage.getItem('refresh'),
    })
    .then(() => {
      sessionStorage.removeItem('access');
      sessionStorage.removeItem('refresh');
      NetworkService.removeAuthentication();
      dispatch(logoutSuccess());
    })
    .catch(() => {})
    .finally(() => dispatch(requestEnded()));
};

type PasswordForm = {
  old_password: string;
  new_password1: string;
  new_password2: string;
  code: string | null;
};

export const changePassword =
  (form: PasswordChangeForm, t: any) => (dispatch: Dispatch, state: RootState, api: AxiosInstance) => {
    dispatch(requestStarting('changePassword'));
    api
      .post<PasswordForm, never>('/api/core/password/change', {
        old_password: form.oldPassword,
        new_password1: form.newPassword,
        new_password2: form.repeatNewPassword,
        code: form.code ?? null,
      })
      .then(() => {
        toastr.success(t('Change Password'), t('Your password has been successfully changed'));
      })
      .catch((error: AxiosError) => parseErrors(error).then(errors => dispatch(loginFailure(errors))))
      .finally(() => dispatch(requestEnded()));
  };

export const enable2fa =
  (form: Enable2faForm, t: any, callback: VoidFunction) =>
  (dispatch: Dispatch, state: RootState, api: AxiosInstance) => {
    dispatch(requestStarting('enable2fa'));
    api
      .post<Enable2faForm, never>('/api/core/2fa', form)
      .then(() => {
        // @ts-ignore
        dispatch(fetchProfile(false));
        toastr.success(t('Enable 2FA'), t('Two factor authentication has been successfully enabled!'));
        if (callback) {
          callback();
        }
      })
      .catch((error: AxiosError) => parseErrors(error).then(errors => dispatch(loginFailure(errors))))
      .finally(() => dispatch(requestEnded()));
  };

export const disable2fa =
  (password: string, t: any, callback: VoidFunction) => (dispatch: Dispatch, state: RootState, api: AxiosInstance) => {
    dispatch(requestStarting('disable2fa'));
    api
      .delete('/api/core/2fa', {data: {password: password}})
      .then(() => {
        // @ts-ignore
        dispatch(fetchProfile(false));
        toastr.success(t('Disable 2FA'), t('Two factor authentication has been successfully disabled!'));
        if (callback) {
          callback();
        }
      })
      .catch((error: AxiosError) => parseErrors(error).then(errors => dispatch(loginFailure(errors))))
      .finally(() => dispatch(requestEnded()));
  };
