import * as React from 'react';
import {Navigate, useLocation} from 'react-router-dom';

import NetworkService from '../../common/NetworkService';

type Props = {
  children: React.ReactElement<any, any>;
};

export const RequireAuth: React.FunctionComponent<Props> = props => {
  const location = useLocation();
  if (!NetworkService.isAuthenticated()) {
    return <Navigate to="/login" state={{from: location}} />;
  }
  return props.children;
};
