import thunk from 'redux-thunk';
import {reducer as toastrReducer} from 'react-redux-toastr';
import {composeWithDevTools} from 'redux-devtools-extension';
import {applyMiddleware, combineReducers, createStore, Reducer} from 'redux';

import {AUTH_LOGOUT, RootState} from './types';
import {commonStateReducer} from './reducers/common';
import NetworkService from '../common/NetworkService';
import {workersStateReducer} from './reducers/workers';
import {servicesStateReducer} from './reducers/services';
import {authStateReducer} from './reducers/authentication';
import {reportingStateReducer} from './reducers/reporting';
import {customersStateReducer} from './reducers/customers';

const appReducer = combineReducers<RootState>({
  auth: authStateReducer,
  common: commonStateReducer,
  customers: customersStateReducer,
  reporting: reportingStateReducer,
  services: servicesStateReducer,
  toastr: toastrReducer,
  workers: workersStateReducer,
});

const rootReducer: Reducer = (state: RootState, action: any) => {
  if (action.type === AUTH_LOGOUT) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export const isDevEnv = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
export const configureStore = () => {
  const middleware = thunk.withExtraArgument(NetworkService.getAxiosApi());
  const enhancer = isDevEnv ? composeWithDevTools(applyMiddleware(middleware)) : applyMiddleware(middleware);

  const store = createStore(rootReducer, enhancer);
  NetworkService.setInterceptor(store.dispatch);
  return store;
};
