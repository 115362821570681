import moment, {Moment, unitOfTime} from 'moment';
import {AxiosError, AxiosResponse} from 'axios';

import 'moment/locale/et';

export enum Granularity {
  DATE,
  JUST_MINUTE,
  MINUTE,
  SECOND,
}

export const formatDateTime = (
  input: string | Moment | Date,
  locale: string,
  granularity: Granularity = Granularity.MINUTE
): string => {
  const date = moment(input);
  date.locale(locale);
  let format = '';
  switch (granularity) {
    case Granularity.DATE:
      format = 'L';
      break;

    case Granularity.JUST_MINUTE:
      format = 'LT';
      break;

    case Granularity.MINUTE:
      format = 'L LT';
      break;

    case Granularity.SECOND:
      format = 'L LTS';
      break;
  }
  return date.format(format);
};

export const makeKey = (source: string): string => source.trim().replace(' ', '-');

const parseErrors = (error: AxiosError<any>): Promise<string[]> => {
  if (error.response?.status === 400) {
    if (typeof error.response.data === 'object' && error.response.data !== null) {
      if (error.response.data.validationErrors) {
        const errors = error.response.data.validationErrors;
        return Promise.resolve(
          [].concat.apply(
            [],
            Object.keys(errors).map(key => errors[key])
          )
        );
      }
      return Promise.resolve(Object.keys(error.response.data).map(key => error.response?.data[key]));
    }
  } else if ([401, 424].includes(error.response?.status ?? 0)) {
    if (typeof error.response?.data === 'object' && error.response.data !== null) {
      if (error.response.data.detail) {
        return Promise.resolve([error.response.data.detail]);
      } else {
        // Could be Blob
        const blob: Blob = error.response.data;
        return blob.text().then(t => [JSON.parse(t).detail]);
      }
    }
  }
  return Promise.resolve([error.message]);
};
export default parseErrors;

export const makeDateForTimeField = (time: string, baseDate: Date): Date => {
  const baseInfo = baseDate.toISOString().split('T');
  return new Date(`${baseInfo[0]}T${time}`);
};

export const downloadResponse = (blobResponse: AxiosResponse<Blob>, defaultExt: string = 'zip') => {
  let fileName = `unnamed.${defaultExt}`;
  const contentHeader: string | undefined = blobResponse.headers['content-disposition'] ?? undefined;
  if (contentHeader) {
    const headerValues = contentHeader.split(';');
    if (headerValues.length === 2 && headerValues[1].includes('filename')) {
      const values = headerValues[1].split('=');
      fileName = values[1].trim();
    }
  }
  const contentType = blobResponse.headers['content-type'] ?? 'application/octet-stream';
  downloadFile([blobResponse.data], fileName, contentType);
};

export const downloadFile = (data: BlobPart[], fileName: string, contentType: string) => {
  /**
   * Constructs a blob from 'data' and makes the user download it.
   */
  const blob = new Blob(data, {type: contentType});
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.style.display = 'none';
  try {
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  } finally {
    document.body.removeChild(link);
    setTimeout(() => URL.revokeObjectURL(url), 5000);
  }
};

export const dateDifference = (
  date1: string | Date | Moment,
  date2: string | Date | Moment,
  unitOfTime: unitOfTime.Diff
): number => {
  const d1 = moment(date1);
  const d2 = moment(date2);
  return Math.abs(d1.diff(d2, unitOfTime));
};
