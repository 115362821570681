import {WORKERS_LOADED, WorkersActions, WorkersState} from '../types';

const initialState: WorkersState = {
  workers: [],
};

export const workersStateReducer = (state: WorkersState = initialState, action: WorkersActions): WorkersState => {
  switch (action.type) {
    case WORKERS_LOADED:
      return {...state, workers: [...action.workers]};

    default:
      return state;
  }
};
