import moment from 'moment';
import {createSelector} from 'reselect';

import {makeDateForTimeField} from '../common/functions';
import {RehaEvent, Location, User, Customer, DaysOfWeek, ServiceReportMini} from '../common/types';
import {AuthState, CommonState, CustomersState, ReportingState, RootState, ServicesState} from './types';

export const selectAuthState = (state: RootState): AuthState => state.auth;
export const selectCommonState = (state: RootState): CommonState => state.common;
export const selectServicesState = (state: RootState): ServicesState => state.services;
export const selectCustomersState = (state: RootState): CustomersState => state.customers;
export const selectReportingState = (state: RootState): ReportingState => state.reporting;

export const eventSelector = createSelector(selectReportingState, (state: ReportingState): RehaEvent[] => {
  const start = moment(state.start);
  const end = moment(state.end);
  return state.events?.filter(evt => moment(evt.start) >= start && moment(evt.end) <= end) ?? [];
});

export const serviceLocationsSelector = createSelector(selectServicesState, (state: ServicesState): Location[] => {
  const avails = state.availabilities.filter(a => Number(DaysOfWeek[a.dayOfWeek]) === state.eventDate?.getDay());
  let locations: Location[] = [];
  for (const avail of avails) {
    if (locations.find(a => a.id === avail.location.id) === undefined) {
      locations.push(avail.location);
    }
  }
  return locations;
});

export const serviceProvidersSelector = createSelector(selectServicesState, (state: ServicesState): User[] => {
  const providers = state.providers.filter(
    p => p.location.id === state.location?.id && p.service.id === state.service?.id
  );
  return providers ? providers.map(p => p.worker) : [];
});

export const customersSelector = createSelector(
  [selectCustomersState, selectServicesState],
  (state: CustomersState, service: ServicesState): Customer[] => {
    return (state.customers ?? []).filter(
      c =>
        c.location.id === service.location?.id &&
        c.availabilities.find(a => Number(DaysOfWeek[a.dayOfWeek]) === service.eventDate?.getDay()) !== undefined &&
        c.availabilities.find(
          a =>
            makeDateForTimeField(a.since, service.eventDate!) <= service.eventDate! &&
            service.eventDate! <= makeDateForTimeField(a.until, service.eventDate!)
        ) !== undefined
    );
  }
);

export const exportableReportsSelector = createSelector(
  [selectReportingState, selectAuthState],
  (state: ReportingState, authState: AuthState): ServiceReportMini[] =>
    state.reports
      ? authState.user?.isAdmin
        ? state.reports?.filter(r => r.id)
        : state.reports?.filter(r => r.hasReport && r.workerId === authState.user?.id)
      : []
);

export const emptyReportsSelector = createSelector(
  [selectReportingState],
  (state: ReportingState): ServiceReportMini[] =>
    state.reports ? state.reports.filter(r => !r.id && moment(r.date) < moment()) : []
);
