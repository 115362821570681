import {AUTH_DENIED, AUTH_FAILURE, AUTH_LOGOUT, AUTH_PROFILE, AuthenticationActions, AuthState} from '../types';
import * as Sentry from '@sentry/browser';

const initialState: AuthState = {
  isAccessDenied: false,
};

export const authStateReducer = (state: AuthState = initialState, action: AuthenticationActions): AuthState => {
  switch (action.type) {
    case AUTH_LOGOUT:
      return {
        ...state,
        user: undefined,
        loginError: undefined,
        isAccessDenied: false,
      };

    case AUTH_FAILURE:
      return {...state, loginError: action.messages};

    case AUTH_DENIED:
      return {...state, isAccessDenied: true, loginError: undefined};

    case AUTH_PROFILE: {
      Sentry.configureScope(scope => {
        const user: Sentry.User = {
          id: String(action.user.id),
          username: action.user.username,
          email: action.user.email,
        };
        scope.setUser(user);
      });
      return {...state, isAccessDenied: false, loginError: undefined, user: {...action.user}};
    }

    default:
      return state;
  }
};
