import {ToastrState} from 'react-redux-toastr';

import {
  Customer,
  ScheduleForParents,
  CustomerServiceHours,
  Location,
  RehaAlert,
  RehaEvent,
  Service,
  ServiceAvailability,
  ServiceProvider,
  ServiceReport,
  User,
  Worker,
  ServiceSummary,
  RehaPlan,
  ServiceReportMini,
} from '../common/types';

export type AuthState = Readonly<{
  user?: User;
  loginError?: string[];
  isAccessDenied: boolean;
}>;

export type CommonState = Readonly<{
  locale: string;
  navOpen: boolean;
  config: Record<string, any>;
  isExecuting: boolean;
  locations: Location[];
  executingModule?: string;
}>;

export type CalendarView = 'month' | 'week' | 'day';
export type ReportingState = Readonly<{
  start: Date;
  end: Date;
  mode: CalendarView;
  alerts?: RehaAlert[];
  events?: RehaEvent[];
  reports?: ServiceReportMini[];
  selectedEvent?: RehaEvent;
  selectedReport?: ServiceReport;
  serviceHours?: CustomerServiceHours[];
  scheduleForParents?: Record<string, ScheduleForParents[]>;
  year?: number;
  month?: number;
}>;

export type CustomersState = Readonly<{
  customers?: Customer[];
  selectedCustomer?: Customer;
  summaries?: ServiceSummary[];
  selectedCustomerMeta?: Record<string, number>;
}>;

export type WorkersState = Readonly<{
  workers: Worker[];
}>;

export type ServicesState = Readonly<{
  eventDate?: Date;
  location?: Location;
  service?: Service;
  providers: ServiceProvider[];
  availabilities: ServiceAvailability[];
}>;

export type RootState = Readonly<{
  auth: AuthState;
  common: CommonState;
  customers: CustomersState;
  reporting: ReportingState;
  services: ServicesState;
  toastr: ToastrState;
  workers: WorkersState;
}>;

/**
 *
 * Action types
 *
 **/
// AUTHENTICATION
export const AUTH_FAILURE = 'REHA_LOGIN_FAILURE';
export const AUTH_DENIED = 'REHA_LOGIN_DENIED';
export const AUTH_LOGOUT = 'REHA_LOGOUT';
export const AUTH_PROFILE = 'REHA_PROFILE';

export type AuthFailureAction = {
  type: typeof AUTH_FAILURE;
  messages: string[];
};

export type AuthDeniedAction = {
  type: typeof AUTH_DENIED;
};

export type AuthLogoutAction = {
  type: typeof AUTH_LOGOUT;
};

export type ProfileSuccessAction = {
  type: typeof AUTH_PROFILE;
  user: User;
};

export type AuthenticationActions = AuthFailureAction | AuthDeniedAction | AuthLogoutAction | ProfileSuccessAction;

// COMMON
export const COMMON_REQUEST_STARTING = 'REHA_REQUEST_EXECUTING';
export const COMMON_REQUEST_ENDED = 'REHA_REQUEST_ENDED';
export const COMMON_LOCALE_CHANGED = 'REHA_UI_LOCALE_CHANGED';
export const COMMON_CONFIG_LOADED = 'REHA_CONFIG_LOADED';
export const COMMON_LOCATIONS_LOADED = 'REHA_LOCATIONS_LOADED';
export const COMMON_SIDEBAR_STATE_CHANGED = 'REHA_SIDEBAR_STATE_CHANGED';

export type RequestStartingAction = {
  type: typeof COMMON_REQUEST_STARTING;
  module: string;
};

export type RequestEndedAction = {
  type: typeof COMMON_REQUEST_ENDED;
};

export type UiLocaleChangedAction = {
  type: typeof COMMON_LOCALE_CHANGED;
  locale: string;
};

export type ConfigLoadedAction = {
  type: typeof COMMON_CONFIG_LOADED;
  config: Record<string, any>;
};

export type LocationsLoadedAction = {
  type: typeof COMMON_LOCATIONS_LOADED;
  locations: Location[];
};

export type SidebarToggleAction = {
  type: typeof COMMON_SIDEBAR_STATE_CHANGED;
  isOpen: boolean;
};

export type CommonActions =
  | RequestStartingAction
  | RequestEndedAction
  | UiLocaleChangedAction
  | ConfigLoadedAction
  | LocationsLoadedAction
  | SidebarToggleAction;

// REPORTING
export const REPORTING_EVENTS_LOADED = 'REHA_EVENTS_LOADED';
export const REPORTING_EVENT_ADDED = 'REHA_EVENT_ADDED';
export const REPORTING_EVENT_MODIFIED = 'REHA_EVENT_MODIFIED';
export const REPORTING_EVENT_DELETED = 'REHA_EVENT_DELETED';
export const REPORTING_EVENT_HOURS_ADDED = 'REHA_EVENT_HOURS_ADDED';
export const REPORTING_EVENT_SELECTED = 'REHA_EVENT_SELECTED';
export const REPORTING_SERVICE_REPORT_LOADED = 'REHA_SERVICE_REPORT_LOADED';
export const REPORTING_SERVICE_REPORTS_LOADED = 'REHA_SERVICE_REPORTS_LOADED';
export const REPORTING_ALERTS_LOADED = 'REHA_ALERTS_LOADED';
export const REPORTING_CLEAR_SELECTED_EVENT = 'REHA_CLEAR_SELECTED_EVENT';
export const REPORTING_CLEAR_SELECTED_REPORT = 'REHA_CLEAR_SELECTED_REPORT';
export const REPORTING_SET_REPORTS_GRID_YEAR = 'REHA_SET_REPORTS_GRID_YEAR';
export const REPORTING_SET_REPORTS_GRID_MONTH = 'REHA_SET_REPORTS_GRID_MONTH';
export const REPORTING_CUSTOMER_SERVICE_HOURS_LOADED = 'REHA_CUSTOMER_SERVICE_HOURS_LOADED';
export const REPORTING_SCHEDULE_FOR_PARENTS_LOADED = 'REHA_SCHEDULE_FOR_PARENTS_LOADED';

export type EventsLoadedAction = {
  type: typeof REPORTING_EVENTS_LOADED;
  events: RehaEvent[];
  start: Date;
  end: Date;
  mode: CalendarView;
};

export type EventAddedAction = {
  type: typeof REPORTING_EVENT_ADDED;
  event: RehaEvent;
  month: number;
  year: number;
};

export type EventModifiedAction = {
  type: typeof REPORTING_EVENT_MODIFIED;
  event: RehaEvent;
};

export type EventDeletedAction = {
  type: typeof REPORTING_EVENT_DELETED;
  id: number;
};

export type EventHoursAddedAction = {
  type: typeof REPORTING_EVENT_HOURS_ADDED;
  report: ServiceReportMini;
};

export type EventSelectedAction = {
  type: typeof REPORTING_EVENT_SELECTED;
  event: RehaEvent;
};

export type ServiceReportLoadedAction = {
  type: typeof REPORTING_SERVICE_REPORT_LOADED;
  report: ServiceReport;
};

export type ServiceReportsLoadedAction = {
  type: typeof REPORTING_SERVICE_REPORTS_LOADED;
  reports: ServiceReportMini[];
};

export type AlertsLoadedAction = {
  type: typeof REPORTING_ALERTS_LOADED;
  alerts: RehaAlert[];
};

export type ClearSelectedEventAction = {
  type: typeof REPORTING_CLEAR_SELECTED_EVENT;
};

export type ClearSelectedReportAction = {
  type: typeof REPORTING_CLEAR_SELECTED_REPORT;
};

export type SetReportsGridSelectedYearAction = {
  type: typeof REPORTING_SET_REPORTS_GRID_YEAR;
  year: number;
};

export type SetReportsGridSelectedMonthAction = {
  type: typeof REPORTING_SET_REPORTS_GRID_MONTH;
  month: number;
};

export type CustomerServiceHoursLoadedAction = {
  type: typeof REPORTING_CUSTOMER_SERVICE_HOURS_LOADED;
  hours: CustomerServiceHours[];
};

export type SchedulesForParentsLoadedAction = {
  type: typeof REPORTING_SCHEDULE_FOR_PARENTS_LOADED;
  schedules: Record<string, ScheduleForParents[]>;
};

export type ReportingActions =
  | EventsLoadedAction
  | EventAddedAction
  | EventModifiedAction
  | EventDeletedAction
  | EventHoursAddedAction
  | EventSelectedAction
  | ServiceReportLoadedAction
  | ServiceReportsLoadedAction
  | AlertsLoadedAction
  | ClearSelectedEventAction
  | ClearSelectedReportAction
  | SetReportsGridSelectedYearAction
  | SetReportsGridSelectedMonthAction
  | CustomerServiceHoursLoadedAction
  | SchedulesForParentsLoadedAction;

// CUSTOMERS
export const CUSTOMERS_LOADED = 'REHA_CUSTOMERS_LOADED';
export const CUSTOMER_LOADED = 'REHA_CUSTOMER_LOADED';
export const CUSTOMER_META_LOADED = 'REHA_CUSTOMER_META_LOADED';
export const CUSTOMER_REHA_PLAN_LOADED = 'REHA_CUSTOMER_REHA_PLAN_LOADED';
export const CUSTOMERS_SERVICE_SUMMARY_LOADED = 'REHA_CUSTOMERS_SERVICE_SUMMARY_LOADED';
export const CUSTOMERS_SERVICE_SUMMARIES_LOADED = 'REHA_CUSTOMERS_SERVICE_SUMMARIES_LOADED';

export type CustomersLoadedAction = {
  type: typeof CUSTOMERS_LOADED;
  customers: Customer[];
};

export type CustomerLoadedAction = {
  type: typeof CUSTOMER_LOADED;
  customer: Customer;
};

export type CustomerMetaLoadedAction = {
  type: typeof CUSTOMER_META_LOADED;
  meta: Record<string, number>;
};

export type ServiceSummariesLoadedAction = {
  type: typeof CUSTOMERS_SERVICE_SUMMARIES_LOADED;
  summaries: ServiceSummary[];
};

export type ServiceSummaryLoadedAction = {
  type: typeof CUSTOMERS_SERVICE_SUMMARY_LOADED;
  summary: ServiceSummary;
};

export type CustomerRehaPlanLoadedAction = {
  type: typeof CUSTOMER_REHA_PLAN_LOADED;
  plan?: RehaPlan;
  id: number;
};

export type CustomersActions =
  | CustomersLoadedAction
  | CustomerLoadedAction
  | CustomerMetaLoadedAction
  | ServiceSummariesLoadedAction
  | ServiceSummaryLoadedAction
  | CustomerRehaPlanLoadedAction;

// WORKERS
export const WORKERS_LOADED = 'REHA_WORKERS_LOADED';

export type WorkersLoadedAction = {
  type: typeof WORKERS_LOADED;
  workers: Worker[];
};

export type WorkersActions = WorkersLoadedAction;

// SERVICES
export const SERVICES_AVAILABILITIES_LOADED = 'REHA_SERVICES_AVAILABILITIES_LOADED';
export const SERVICES_PROVIDERS_LOADED = 'REHA_SERVICES_PROVIDERS_LOADED';
export const SERVICES_EVENT_DATE_CHANGED = 'REHA_SERVICES_EVENT_DATE_CHANGED';
export const SERVICES_EVENT_LOCATION_CHANGED = 'REHA_SERVICES_EVENT_LOCATION_CHANGED';
export const SERVICES_EVENT_SERVICE_CHANGED = 'REHA_SERVICES_EVENT_SERVICE_CHANGED';

export type ServiceAvailabilitiesLoadedAction = {
  type: typeof SERVICES_AVAILABILITIES_LOADED;
  availabilities: ServiceAvailability[];
};

export type ServiceProvidersLoadedAction = {
  type: typeof SERVICES_PROVIDERS_LOADED;
  providers: ServiceProvider[];
};

export type ServiceCreationEventDateChangedAction = {
  type: typeof SERVICES_EVENT_DATE_CHANGED;
  date: Date;
};

export type ServiceCreationEventLocationChangedAction = {
  type: typeof SERVICES_EVENT_LOCATION_CHANGED;
  location: Location;
};

export type ServiceCreationEventServiceChangedAction = {
  type: typeof SERVICES_EVENT_SERVICE_CHANGED;
  service: Service;
};

export type ServicesActions =
  | ServiceAvailabilitiesLoadedAction
  | ServiceProvidersLoadedAction
  | ServiceCreationEventDateChangedAction
  | ServiceCreationEventLocationChangedAction
  | ServiceCreationEventServiceChangedAction;
