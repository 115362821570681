import * as React from 'react';
import {AxiosError} from 'axios';
import {TranslatorProvider} from 'react-translate';

import ErrorPage from './pages/common/ErrorPage';
import {getTranslations} from './common/translations';

type Props = {
  error: AxiosError;
};

const StaticApp: React.FunctionComponent<Props> = ({error}) => {
  return (
    <TranslatorProvider translations={getTranslations('en')}>
      <ErrorPage error={error} />
    </TranslatorProvider>
  );
};
export default StaticApp;
