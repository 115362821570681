import {
  CUSTOMER_LOADED,
  CUSTOMER_META_LOADED,
  CUSTOMER_REHA_PLAN_LOADED,
  CUSTOMERS_LOADED,
  CUSTOMERS_SERVICE_SUMMARIES_LOADED,
  CUSTOMERS_SERVICE_SUMMARY_LOADED,
  CustomersActions,
  CustomersState,
} from '../types';

const initialState: CustomersState = {};

export const customersStateReducer = (
  state: CustomersState = initialState,
  action: CustomersActions
): CustomersState => {
  switch (action.type) {
    case CUSTOMERS_LOADED:
      return {...state, customers: [...action.customers]};

    case CUSTOMER_LOADED:
      return {...state, selectedCustomer: {...action.customer}};

    case CUSTOMER_META_LOADED:
      return {...state, selectedCustomerMeta: {...action.meta}};

    case CUSTOMERS_SERVICE_SUMMARIES_LOADED:
      return {...state, summaries: [...action.summaries]};

    case CUSTOMERS_SERVICE_SUMMARY_LOADED:
      return {...state, summaries: [...(state.summaries ?? []), {...action.summary}]};

    case CUSTOMER_REHA_PLAN_LOADED:
      return {
        ...state,
        customers: state.customers?.map(c =>
          c.id === action.id ? {...c, rehaPlan: action.plan ? {...action.plan} : undefined} : {...c}
        ),
      };

    default:
      return state;
  }
};
