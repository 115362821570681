import * as React from 'react';
import {AxiosError} from 'axios';
import {NavLink} from 'react-router-dom';
import {useTranslate} from 'react-translate';

type Props = {
  error?: AxiosError;
};

const ErrorPage: React.FunctionComponent<Props> = ({error}) => {
  const t = useTranslate('ErrorPage');

  React.useEffect(() => {
    document.body.classList.add('error-body');
    return () => document.body.classList.remove('error-body');
  }, []);

  return (
    <div className="message">
      <h1 className="eh1">500</h1>
      <h3 className="eh3 mt-2">{t('Server Error')}</h3>
      <h2 className="eh2">{t("It's not you, it's me.")}</h2>
      {error ? (
        <div>{error.response?.statusText}</div>
      ) : (
        <NavLink to="/" className="back-button">
          {t('Go Back')}
        </NavLink>
      )}
    </div>
  );
};
export default ErrorPage;
