import axios from 'axios';
import {getLanguage} from './NetworkService';

export type AppConfig = {
  environment: string;
  version: string;
  sentryDsn: string;
  profilerRate: number;
};

export const getAppConfig = (): Promise<AppConfig> => {
  const locale = getLanguage();
  return axios.get<AppConfig>(`/${locale}/api/core/public-config`).then(result => result.data);
};
